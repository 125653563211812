import styled from 'styled-components';

export const LoadingSt = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img{
    width: 100%;
    max-width: 100px;
  }

  svg{
    font-size: 30px;
    margin-top: 20px;
    animation: 2s linear 1s infinite load;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg); 
    }

    100% {
      transform: rotate(360deg); 
    }
  }
`;
