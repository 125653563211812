import React, { useEffect, useState } from 'react';
import { IoDocumentOutline, IoDocumentsOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { FaPencil, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { deleteDoc, doc } from 'firebase/firestore';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import useOrder from '../../store/Order';
import useUser from '../../store/User';
import { PendingTables } from '../Pending/style';
import { db } from '../../service/firebaseConnection';
import EditKits from '../../components/EditKits';
import { MyRequestFilter, MyRequestFilterGroup, MyRequestFilterInput } from '../MyRequest/style';

export default function Kits() {
  const [show, setShow] = useState(false);
  const [showKit, setShowKit] = useState(false);
  const [objId, setObjId] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const { user } = useUser();
  const {
    kits, fetchOrderKits, setOrderKits, setOrderSelect,
  } = useOrder();

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderKits(user.uid);
  }, [user]);

  const handleDelete = async (id) => {
    const orderId = kits.filter((od) => od.id !== id);

    try {
      const docRef = doc(db, 'kits', id);
      await deleteDoc(docRef);
      setOrderKits(orderId);
    } catch (error) {
      console.error('Erro ao deletar o documento: ', error);
    }
  };

  const changeId = (id) => {
    const orderCartList = kits.filter((oc) => oc.id === id);
    setObjId(orderCartList[0]);
  };

  const handleForm = (id) => {
    const orderCartList = kits.filter((oc) => oc.id === id);
    setOrderSelect(orderCartList[0].orderSelect);
    navigate('/form');
  };

  const filteredKits = kits && kits.filter((kit) => {
    const kitNameMatch = kit.name.toLowerCase().includes(searchName.toLowerCase());
    const kitValue = kit.orderSelect.reduce((total, item) => total + parseFloat(item.prince), 0);
    const minValueMatch = minValue === '' || kitValue >= parseFloat(minValue);
    const maxValueMatch = maxValue === '' || kitValue <= parseFloat(maxValue);

    return kitNameMatch && minValueMatch && maxValueMatch;
  });

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Meus kits" url="kits" icon={<IoDocumentsOutline />} />

          <styles.KitsSt>
            <styles.KitsButton>
              <Link to="/requestkit">
                <FaPlus />
                Criar kit
              </Link>
            </styles.KitsButton>

            {kits && (
              kits.length > 0 && (
                <MyRequestFilter>
                  <button type="button" onClick={() => setShow(!show)}>
                    Filtro

                    {show ? <IoIosArrowDown /> : <IoIosArrowUp /> }

                  </button>

                  {show && (
                  <MyRequestFilterGroup>
                    <MyRequestFilterInput>
                      <span>Nome do pedido</span>
                      <input
                        type="text"
                        placeholder="Pesquisar por nome"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                    </MyRequestFilterInput>

                    <MyRequestFilterInput>
                      <span>Valor minimo</span>
                      <input
                        type="number"
                        placeholder="Valor mínimo"
                        value={minValue}
                        onChange={(e) => setMinValue(e.target.value)}
                      />
                    </MyRequestFilterInput>

                    <MyRequestFilterInput>
                      <span>Valor máximo</span>
                      <input
                        type="number"
                        placeholder="Valor máximo"
                        value={maxValue}
                        onChange={(e) => setMaxValue(e.target.value)}
                      />
                    </MyRequestFilterInput>
                  </MyRequestFilterGroup>
                  )}

                </MyRequestFilter>
              )
            )}

            {filteredKits && (
              filteredKits.length > 0 ? (
                <PendingTables>
                  <thead>
                    <tr>
                      <th>Nome do kit</th>
                      <th>Documentos selecionados</th>
                      <th>Valor</th>
                      <th>#</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredKits.map((kit) => (
                      <tr key={kit.id}>
                        <td>{kit.name}</td>
                        <td>{kit.orderSelect.length}</td>
                        <td>
                          R$
                          {' '}
                          {kit.orderSelect.reduce((total, item) => total + parseFloat(item.prince), 0).toFixed(2).replace('.', ',')}
                        </td>
                        <td>
                          <div>
                            <button type="button" onClick={() => { setShowKit(true); changeId(kit.id); }}><FaPencil /></button>
                            <button type="button" className="hidden" />
                            <button type="button" onClick={() => handleDelete(kit.id)}><MdDelete /></button>
                            <button type="button" onClick={() => { handleForm(kit.id); }} className="order"><IoDocumentOutline /></button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </PendingTables>
              ) : (
                <styles.KitsClear>
                  <IoDocumentsOutline />
                  <h3>Nenhum kit encontrado</h3>
                  <span>Kits são formas inteligentes de criar um pedido composto de documentos. Comece agora mesmo.</span>
                </styles.KitsClear>
              )
            )}
          </styles.KitsSt>

          {showKit && (
            <EditKits func={() => setShowKit(false)} obj={objId} />
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
