import React, {
  useEffect,
} from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import Center from '../../components/Center';
import * as styles from './style';
import banner2 from '../../assets/section-2.png';
import Header from '../../components/Header';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import useUser from '../../store/User';
import useApplication from '../../store/Application';

export default function Dashboard() {
  const {
    user, loading,
  } = useUser();

  const {
    application, fetchApplication,
  } = useApplication();

  useEffect(() => {
    console.log(user);
    console.log(application);
  }, [application]);

  useEffect(() => {
    if (user) {
      fetchApplication(user.uid);
    }
  }, [user]);

  const handleAppl = (status) => {
    // Find the application that matches the given status
    const applicationItem = application.filter((appl) => appl.status === status);

    if (applicationItem) {
      return applicationItem.length;
    }

    return false;
  };

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  const changeIcon = (status) => {
    switch (status) {
      case 'pending':
        return 'icon_access_false';

      case 'progress':
        return 'icon_access_progress';

      default:
        return 'icon_access_true';
    }
  };

  if (loading || !user) {
    return <Loading />;
  }

  if (user) {
    if (!user.access && user.hasOwnProperty('access')) {
      return (
        <article className="article_flex">

          <Header />

          <Center>
            <styles.DashboardSt>

              <Title text="Painel principal" url="dashboard" icon={<AiOutlineHome />} />

              <styles.DashboardAccess>
                <h3>Para conseguir utilizar nossa plataforma entre em contato</h3>

                <styles.DashboardAccessButton>
                  Entrar em contato
                </styles.DashboardAccessButton>

                <h4>Já entrou em contato? Aguarde, em menos de 3 dias úteis já tera acesso a plataforma inteira</h4>
              </styles.DashboardAccess>
            </styles.DashboardSt>
          </Center>
        </article>
      );
    }
    return (
      <article className="article_flex">

        <Header />
        <Center>
          <styles.DashboardSt>

            <Title text="Painel de controle" url="dashboard" icon={<AiOutlineHome />} />

            <styles.DashboardDiv>

              <styles.DashboardBtns>
                <h2>Facilite sua vida com a JK certidões</h2>

                <div>
                  <div>
                    <span>Solicitar a certidão</span>
                    <Link to="/formdefault">Solicitar</Link>
                  </div>

                  <div>
                    <span>Solicitar pesquisa</span>
                    <Link to="/requestsearch">Solicitar</Link>
                  </div>
                </div>
              </styles.DashboardBtns>

              <styles.DashboardText $isBanner={banner2}>
                <h2>
                  Crie seus pedidos agora mesmo
                </h2>

                {application && (
                <styles.DashboardTextInfo>
                  <div>
                    <h3>{handleAppl('finish')}</h3>
                    <span>Pedidos finalizados</span>
                  </div>

                  <div>
                    <h3>{handleAppl('process')}</h3>
                    <span>Pedidos em processos</span>
                  </div>

                  <div>
                    <h3>{handleAppl('pending')}</h3>
                    <span>Pedidos pendentes</span>
                  </div>
                </styles.DashboardTextInfo>
                )}
              </styles.DashboardText>
            </styles.DashboardDiv>

            <styles.DashboardRequests className="box_shadow">
              <h2>Pedidos recentes</h2>

              {
                application ? (

                  <styles.DashboardAppl>
                    {/* <thead>
                      <tr>

                        <span>Número do pedido</span>
                        <span>Nome do pedido</span>
                        <span>Data</span>
                        <span>Valor</span>
                        <span>
                          Status
                        </span>
                        <span>#</span>
                      </tr>
                    </thead> */}

                    {application.map((apl) => (

                      <tbody>
                        <tr>
                          <span>{apl.numberId}</span>
                          <span>{apl.nameOrder}</span>
                          <span>{apl.dateFormat}</span>
                          <span>
                            R$
                            {parseFloat(apl.prince).toFixed(2).replace('.', ',')}
                          </span>
                          <span className={changeCheck(apl.status)}>
                            {apl.status === 'pending' ? 'Pendente' : ''}
                            {apl.status === 'progress' ? 'Em andamento' : ''}
                            {apl.status === 'finish' ? 'Finalizado' : ''}
                          </span>
                          <Link to={`/myrequest/${apl.id}`}><HiOutlineDocumentReport className={changeIcon(apl.status)} size={26} /></Link>
                        </tr>
                      </tbody>

                    ))}
                  </styles.DashboardAppl>
                ) : (
                  <div>
                    Não há pedidos
                  </div>
                )
              }
            </styles.DashboardRequests>
          </styles.DashboardSt>
        </Center>

      </article>
    );
  }

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <styles.DashboardSt>

          <Title text="Painel principal" url="dashboard" icon={<AiOutlineHome />} />

          <styles.DashboardAccess>
            <h3>Algo deu errado!</h3>

            <styles.DashboardAccessButton>
              Recarregar a página
            </styles.DashboardAccessButton>

            <h4>Se o problema persistir, entre em contato</h4>
          </styles.DashboardAccess>
        </styles.DashboardSt>
      </Center>

    </article>
  );
}
