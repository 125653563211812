import React, { useEffect, useRef, useState } from 'react';
import { TiDocumentText } from 'react-icons/ti';
import { GrDocumentConfig, GrDocumentUser, GrRadialSelected } from 'react-icons/gr';
import { TbHammer } from 'react-icons/tb';
import { BiHomeAlt2 } from 'react-icons/bi';
import { IoHomeSharp, IoNewspaperOutline } from 'react-icons/io5';
import { GiBrazil } from 'react-icons/gi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaArrowLeft, FaArrowRight, FaCar } from 'react-icons/fa6';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import * as styles from '../Documents/style';
import useOrder from '../../store/Order';
import { PopUpWarn } from '../../Popups';
import { db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function DocumentsKits() {
  const [orderFilter, setOrderFilter] = useState([]);
  const [orderFilterOption, setOrderFilterOption] = useState('');
  const [verif, setVerif] = useState('order');
  const [title, setTitle] = useState('');
  const {
    fetchOrders, order, setOrderSelect, orderSelect,
  } = useOrder();

  const navigate = useNavigate();

  const { user } = useUser();

  const scrollRef = useRef(null);

  const operations = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Registro Civil', icon: <GrDocumentUser />, value: 'registro-civil' },
    { name: 'Registro de Imóveis', icon: <BiHomeAlt2 size="20px" />, value: 'registro-de-imoveis' },
    { name: 'Notas', icon: <IoNewspaperOutline />, value: 'notas' },
    { name: 'Protesto', icon: <TbHammer />, value: 'protesto' },
    { name: 'Federais', icon: <GiBrazil />, value: 'federais' },
    { name: 'Estaduais', icon: <GiBrazil />, value: 'estaduais' },
    { name: 'Municipais', icon: <GiBrazil />, value: 'municipais' },
  ];

  const operationsSearch = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Pessoa/Empresa', icon: <FiUser />, value: 'pessoas-empresa' },
    { name: 'Débitos/Pendências', icon: <GrDocumentConfig />, value: 'debitos-pendencias' },
    { name: 'Bens Móveis', icon: <FaCar />, value: 'bens-moveis' },
    { name: 'Bens Móveis', icon: <IoHomeSharp />, value: 'bens-imoveis' },
  ];

  useEffect(() => {
    fetchOrders();
    setOrderSelect([]);
  }, []);

  useEffect(() => {
    if (order) {
      const orderFilterList = order.filter((od) => od.type === verif);

      setOrderFilter(orderFilterList);
    }

    if (orderFilterOption) {
      const orderFilterList = order.filter((od) => od.type === verif);
      const orderFilterListOption = orderFilterList.filter((od) => od.selectedOption === orderFilterOption);

      setOrderFilter(orderFilterListOption);
    }
  }, [order, orderFilterOption, verif]);

  const handleOrder = (id) => {
    const isSelected = orderSelect.filter((od) => od.id === id);

    if (isSelected) {
      if (isSelected.length > 0) {
        const updatedOrderSelect = orderSelect.filter((od) => od.id !== id);

        setOrderSelect(updatedOrderSelect);
      } else {
        const orderId = orderFilter.filter((od) => od.id === id);

        const dataOrder = orderSelect.map((od) => od);
        dataOrder.push(orderId[0]);

        if (orderId) {
          setOrderSelect(dataOrder);
        }
      }
    }
  };

  const searchIcon = (icon) => {
    switch (icon) {
      case 'registro-civil':
        return <GrDocumentUser />;
      case 'notas':
        return <IoNewspaperOutline />;
      case 'registro-de-imoveis':
        return <BiHomeAlt2 />;
      case 'protesto':
        return <TbHammer />;
      case 'federais':
        return <GiBrazil />;
      case 'estaduais':
        return <GiBrazil />;
      case 'municipais':
        return <GiBrazil />;
      case 'pessoas-empresa':
        return <FiUser />;
      case 'debitos-pendencias':
        return <GrDocumentConfig />;
      case 'bens-moveis':
        return <FaCar />;
      case 'bens-imoveis':
        return <IoHomeSharp />;
      default:
        return null;
    }
  };

  const isSelected = (id) => (orderSelect ? orderSelect.some((od) => od.id === id) : false);

  const scrollLeft = () => {
    if (scrollRef.current) {
      console.log(scrollRef.current.scrollBy());
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const handleSelect = async () => {
    if (title) {
      if (orderSelect.length > 0) {
        const dataSelect = {
          userUid: user.uid,
          name: title,
          orderSelect,
        };

        try {
          await addDoc(collection(db, 'kits'), dataSelect);

          setOrderSelect([]);
          setTitle('');

          navigate('/kits');
        } catch (error) {
          console.log(error);
        }
      } else {
        PopUpWarn('Selecione algum documento');
      }
    } else {
      PopUpWarn('Selecione o nome para seu kit');
    }
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <styles.DocumentSt>
          <Title text="Solicitar um Kit" url="requestkit" icon={<TiDocumentText />} />

          <styles.DocumentVerif $isVerif={verif !== 'search'}>
            <button type="button" onClick={() => setVerif('order')}>Certidões</button>
            <button type="button" onClick={() => setVerif('search')}>Pesquisas</button>
          </styles.DocumentVerif>

          <styles.DocumentTitle>
            <h3>Selecione um nome para seu kit</h3>

            <input type="text" placeholder="Digite um nome para o kit" value={title} onChange={(e) => setTitle(e.target.value)} />
          </styles.DocumentTitle>

          <styles.DocumentRequest>
            <h3>Selecione qual documento deseja</h3>

            <div className="scroll">
              <button type="button" onClick={scrollLeft}><FaArrowLeft /></button>

              {verif === 'order' && (
                <styles.DocumentSelection ref={scrollRef}>
                  {operations.map((op) => (
                    <button type="button" onClick={() => setOrderFilterOption(op.value)}>
                      {op.icon}
                      <span>{op.name}</span>
                    </button>
                  ))}
                </styles.DocumentSelection>
              )}

              {verif !== 'order' && (
                <styles.DocumentSelection ref={scrollRef}>
                  {operationsSearch.map((op) => (
                    <button type="button" onClick={() => setOrderFilterOption(op.value)}>
                      {op.icon}
                      <span>{op.name}</span>
                    </button>
                  ))}
                </styles.DocumentSelection>
              )}

              <button type="button" onClick={scrollRight}><FaArrowRight /></button>
            </div>

            <styles.DocumentGroupInput>

              {orderFilter && (

                orderFilter.map((od) => (
                  <styles.DocumentInput
                    id={od.id}
                    onClick={() => handleOrder(od.id)}
                    className={isSelected(od.id) ? 'input_select' : ''}
                  >
                    {searchIcon(od.selectedOption)}
                    <span>{od.name}</span>

                    <button type="button">
                      <GrRadialSelected />
                    </button>
                  </styles.DocumentInput>
                ))

              )}

            </styles.DocumentGroupInput>

          </styles.DocumentRequest>

          <styles.DocumentSend className="box_shadow">
            <span>
              Documentos selecionados:
              {' '}
              {orderSelect && (
                <b>{orderSelect.length}</b>
              )}
            </span>

            <button type="button" onClick={handleSelect}>
              Criar kit
            </button>
          </styles.DocumentSend>

        </styles.DocumentSt>
      </Center>
    </article>
  );
}
