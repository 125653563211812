import React from 'react';
import { FaLaptopCode } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import Center from '../../components/Center';
import Header from '../../components/Header';
import * as styles from './style';

export default function Development() {
  return (
    <article className="article_flex">

      <Header />
      <Center>
        <styles.DevelopmentSt>
          <FaLaptopCode />
          <h2>Página em desenvolvimento</h2>
          <p>Logo esta funcionalidade estará 100% pronta para facilitar o seu processo</p>
          <Link to="/">Voltar para o inicio</Link>
        </styles.DevelopmentSt>
      </Center>

    </article>
  );
}
