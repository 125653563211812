import styled from 'styled-components';

export const DevelopmentSt = styled.section`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg{
    font-size: 64px;
  }

  h2{
    font-size: 22px;
  }

  p{
    font-size: 16px;
    margin: 5px 0 25px 0;
  }

  a{
    width: 100%;
    max-width: 350px;
    border: 1px solid ${(props) => props.theme.layout};
    background-color: ${(props) => props.theme.layout};
    text-align: center;
    padding: 7px;
    border-radius: 0.2rem;
    font-size: 16px;
    color: ${(props) => props.theme.textLigth};

    &:hover{
      transition: 0.7s;
      color: ${(props) => props.theme.layout};
      background-color: transparent;
    }
  }
`;
