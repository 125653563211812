import React, { useEffect, useState } from 'react';
import {
  FaCartShopping, FaInfo, FaPencil, FaPlus,
} from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import Title from '../../components/Title';
import Center from '../../components/Center';
import Header from '../../components/Header';
import useOrder from '../../store/Order';
import * as styles from './style';
import useUser from '../../store/User';
import { db } from '../../service/firebaseConnection';
import EditAppl from '../../components/EditAppl';
import InfoAppl from '../../components/InfoAppl';

export default function Pending() {
  const [prince, setPrince] = useState(0);
  const [objId, setObjId] = useState(null);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const { orderCart, setOrderCart, fetchOrderCart } = useOrder();
  const { user, setLoading } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderCart();
  }, []);

  useEffect(() => {
    console.log(orderCart);
    if (Array.isArray(orderCart) && orderCart.length > 0) {
      const totalPrince = orderCart.reduce((total, order) => total + order.users.reduce((userTotal, us) => userTotal + us.orderSelect.reduce((sum, item) => sum + parseFloat(item.prince), 0), 0), 0);

      setPrince(totalPrince);
    }
  }, [orderCart]);

  const handleDelete = (id) => {
    const orderId = orderCart.filter((od) => od.id !== id);
    setOrderCart(orderId);
  };

  const addOrders = async (e) => {
    e.preventDefault();

    const dataFormat = [];

    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'application'));
      const existingCount = querySnapshot.size;
      let nextId = existingCount + 1;

      orderCart.forEach((element) => {
        const {
          date = '',
          dateFormat = '',
          userDoc = '',
          userUid = '',
          nameOrder = '',
          users = [],
        } = element;

        const totalPrince = users.reduce((userTotal, usr) => {
          const orderSelectTotal = usr.orderSelect.reduce((sum, item) => sum + parseFloat(item.prince), 0);
          return userTotal + orderSelectTotal;
        }, 0);

        const data = {
          numberId: nextId,
          date,
          dateFormat,
          userDoc,
          userUid,
          prince: totalPrince,
          status: 'pending',
          nameOrder,
          pagament: user.access ? 'approved' : 'pending',
          users,
        };

        dataFormat.push(data);
        nextId += 1;
      });

      await Promise.all(dataFormat.map((data) => addDoc(collection(db, 'application'), data)));

      setOrderCart([]);
      navigate('/myrequest');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeId = (id) => {
    const orderCartList = orderCart.find((oc) => oc.id === id);
    setObjId(orderCartList);
  };

  return (
    <article className="article_flex">
      <Header />
      <Center>
        <styles.PendingDocumentSt>
          <Title text="Pedidos aguardando finalização" url="pending" icon={<FaCartShopping />} />

          {(!orderCart || orderCart.length === 0) && (
            <styles.PendingClear>
              <FaCartShopping />
              <h3>Sem pedidos</h3>
              <span>Realize um pedido, entre e selecione uma das opções e efetue seu pedido</span>
              <Link to="/request">Realize um pedido agora mesmo</Link>
            </styles.PendingClear>
          )}

          {orderCart && orderCart.length > 0 && (
            <>
              <styles.PendingSt>
                <h3>Pedidos pendentes</h3>
                <span>Para prosseguir com os pedidos efetue o pagamento</span>
              </styles.PendingSt>
              <styles.PendingSend className="box_shadow">
                <styles.PendingSendBuy>
                  <Link to="/request">
                    <FaPlus />
                    Continuar comprando
                  </Link>
                </styles.PendingSendBuy>
                <span>
                  Pedidos totais:
                  {' '}
                  <b>{orderCart.length}</b>
                </span>
                <span>
                  Valor total:
                  {' '}
                  <b>
                    R$
                    {prince.toFixed(2).replace('.', ',')}
                  </b>
                </span>
                <button type="submit" onClick={addOrders}>Finalizar pedido</button>
              </styles.PendingSend>
              <styles.PendingTables>
                <thead>
                  <tr>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Usuário</th>
                    <th>Solicitações</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {orderCart.map((od) => {
                    const totalPrince = od.users.reduce((userTotal, us) => {
                      const orderSelectTotal = us.orderSelect.reduce((sum, item) => sum + parseFloat(item.prince), 0);
                      return userTotal + orderSelectTotal;
                    }, 0);

                    return (
                      <tr key={od.id}>
                        <td>{od.nameOrder}</td>
                        <td>{od.dateFormat}</td>
                        <td>
                          R$
                          {totalPrince.toFixed(2).replace('.', ',')}
                        </td>
                        <td>
                          <img src={user.img} alt="logo" title={user.name} />
                        </td>
                        <td>{od.users.length}</td>
                        <td>
                          <div>
                            <button type="button" onClick={() => { setShow(true); changeId(od.id); }}><FaPencil /></button>
                            <button type="button" onClick={() => { setShowInfo(true); changeId(od.id); }}><FaInfo /></button>
                            <button type="button" onClick={() => handleDelete(od.id)}><MdDelete /></button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </styles.PendingTables>
            </>
          )}

          {show && <EditAppl func={() => setShow(false)} obj={objId} />}
          {showInfo && <InfoAppl func={() => setShowInfo(false)} obj={objId} />}
        </styles.PendingDocumentSt>
      </Center>
    </article>
  );
}
