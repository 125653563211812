import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { FaCartShopping } from 'react-icons/fa6';
import * as styles from './style';
import banner from '../../assets/section.png';

export default function Title({
  text, url, icon,
}) {
  return (
    <styles.TitleSt $isBanner={banner}>

      <div>

        <h1>{text}</h1>

        <styles.TitleUrl>
          <Link to="/">
            <AiOutlineHome />
            <span>Home</span>
          </Link>
          {' '}
          <p>/</p>
          {' '}
          <Link to={`/${url}`}>
            {icon}
            <span>{text}</span>
          </Link>
        </styles.TitleUrl>
      </div>

      <styles.TitleCart>
        <Link to="/pending">
          <FaCartShopping />
          <span>Ver pedidos</span>
        </Link>
      </styles.TitleCart>

    </styles.TitleSt>
  );
}
