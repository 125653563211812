import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import { IoWarning } from 'react-icons/io5';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  collection, doc, getDocs, query, setDoc, where,
} from 'firebase/firestore';
import { auth, db } from '../../service/firebaseConnection';
import * as styles from '../SignIn/style';
import banner from '../../assets/sign-banner.png';
import useUser from '../../store/User';

export default function SignUp() {
  const [verif, setVerif] = useState(true);
  const [warn, setWarn] = useState(false);
  const [warnText, setWarnText] = useState('');
  const [load, setLoad] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const [nameCompany, setNameCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { storageUser } = useUser();

  const handleVerif = (prop) => {
    setVerif(prop);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoad(true);

      if (cpf === '' && verif) {
        setWarn(true);
        throw new Error('O seu cpf está vazio!');
      }

      if (cnpj === '' && !verif) {
        setWarn(true);
        throw new Error('O seu cnpj está vazio!');
      }

      if (tel === '') {
        setWarn(true);
        throw new Error('O seu telefone está vazio!');
      }

      if (name === '' && verif) {
        setWarn(true);
        throw new Error('O seu nome está vazio!');
      }

      if (nameCompany === '' && !verif) {
        setWarn(true);
        throw new Error('O nome da sua empresa está vazio!');
      }

      if (email === '') {
        setWarn(true);
        throw new Error('O email está vazio!');
      }

      if (password === '') {
        setWarn(true);
        throw new Error('A senha está vazia!');
      }

      if (verif) {
        const q = query(collection(db, 'users'), where('cpf', '==', cpf));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async (value) => {
              const { uid } = value.user;

              await setDoc(doc(db, 'users', uid), {
                doc: cpf,
                img: 'https://firebasestorage.googleapis.com/v0/b/jk-sistemas.appspot.com/o/1.png?alt=media&token=83f2b9d6-0997-4068-957c-447e279d8354',
                tel,
                name,
                email,
              })
                .then(() => {
                  const data = {
                    uid,
                    email: value.user.email,
                    name,
                    img: 'https://firebasestorage.googleapis.com/v0/b/jk-sistemas.appspot.com/o/1.png?alt=media&token=83f2b9d6-0997-4068-957c-447e279d8354',
                  };

                  storageUser(data);
                })
                .catch((error) => {
                  throw Error(error.message);
                });
            })
            .catch((error) => {
              throw Error(error.message);
            });
        } else {
          setWarn(true);
          throw new Error('Este cpf já está cadastrado!');
        }
      } else {
        const q = query(collection(db, 'users'), where('cnpj', '==', cnpj));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async (value) => {
              const { uid } = value.user;

              await setDoc(doc(db, 'users', uid), {
                doc: cnpj,
                img: 'https://firebasestorage.googleapis.com/v0/b/jk-sistemas.appspot.com/o/1.png?alt=media&token=83f2b9d6-0997-4068-957c-447e279d8354',
                access: false,
                tel,
                name: nameCompany,
                email,
              })
                .then(() => {
                  const data = {
                    uid,
                    email: value.user.email,
                    name: nameCompany,
                    img: 'https://firebasestorage.googleapis.com/v0/b/jk-sistemas.appspot.com/o/1.png?alt=media&token=83f2b9d6-0997-4068-957c-447e279d8354',
                  };

                  storageUser(data);
                })
                .catch((error) => {
                  throw Error(error.message);
                });
            })
            .catch((error) => {
              throw Error(error.message);
            });
        } else {
          setWarn(true);
          throw new Error('Este cnpj já está cadastrado!');
        }
      }
    } catch (error) {
      const result = error.message.split(/\(([^)]+)\)/, 2);

      const errorMsg = result[1];
      setWarnText(error.message);

      switch (errorMsg) {
        case 'auth/weak-password':
          setWarn(true);
          setWarnText('A senha precisa ter no minímo 6 caracteres');
          break;

        case 'auth/email-already-in-use':
          setWarn(true);
          setWarnText('O email já está em uso');
          break;

        case 'auth/invalid-email':
          setWarn(true);
          setWarnText('Digite um email válido');
          break;

        default:
          break;
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <styles.Sing>
      <styles.SingBanner $isBanner={banner} />

      <styles.SingInfo>
        <h2>Bem vindo a plataforma JK</h2>
        <span>Certidões, Imóveis e Construções</span>

        <h3>Crie sua conta</h3>

        <styles.SingForm>

          <styles.SingVerif $isVerif={verif}>
            <button type="button" onClick={() => handleVerif(true)}>Pessoa Física</button>
            <button type="button" onClick={() => handleVerif(false)}>Pessoa Juridica</button>
          </styles.SingVerif>

          {warn && (
            <styles.SingWarn>
              <IoWarning />
              <h3>{warnText}</h3>
            </styles.SingWarn>
          )}

          <styles.SingGroupInputRow>

            {verif && (
              <div>
                <span>Cpf</span>
                <IMaskInput mask="000.000.000-00" type="text" name="cpf" placeholder="Digite seu CPF" value={cpf} onChange={(e) => setCpf(e.target.value)} />
              </div>
            )}

            {!verif && (
              <div>
                <span>Cnpj</span>
                <IMaskInput mask="00.000.000/0000-00" type="text" name="cnpj" placeholder="Digite seu CNPJ" value={cnpj} onChange={(e) => setCnpj(e.target.value)} />
              </div>
            )}

            <div>
              <span>Telefone</span>
              <IMaskInput mask="(00) 00000-0000" type="text" name="tel" placeholder="Digite seu telefone" value={tel} onChange={(e) => setTel(e.target.value)} />
            </div>
          </styles.SingGroupInputRow>

          <styles.SingGroupInput>
            {verif && (
              <>
                <span>Nome</span>
                <input type="text" name="name" placeholder="Digite seu nome completo" value={name} onChange={(e) => setName(e.target.value)} />
              </>
            )}

            {!verif && (
              <>
                <span>Nome da empresa</span>
                <input type="text" name="name" placeholder="Digite o nome da empresa" value={nameCompany} onChange={(e) => setNameCompany(e.target.value)} />
              </>
            )}
          </styles.SingGroupInput>

          <styles.SingGroupInput>
            <span>Email</span>
            <input type="email" name="email" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
          </styles.SingGroupInput>

          <styles.SingGroupInput>
            <span>Senha</span>
            <input type="password" name="password" placeholder="Crie sua senha" value={password} onChange={(e) => setPassword(e.target.value)} />

            <styles.SingPasswordRules>
              <li>A senha deve conter no minímo 6 caracteres </li>
            </styles.SingPasswordRules>
          </styles.SingGroupInput>

          <button type="submit" onClick={handleSubmit}>{load ? 'Criando...' : 'Criar Conta'}</button>
        </styles.SingForm>

        <p>Já tem uma conta?</p>

        <Link to="/">Faça login</Link>

      </styles.SingInfo>

    </styles.Sing>
  );
}
