import React, { useEffect, useRef, useState } from 'react';
import { TiDocumentText } from 'react-icons/ti';
import { GrDocumentUser, GrRadialSelected } from 'react-icons/gr';
import { TbHammer } from 'react-icons/tb';
import { BiHomeAlt2 } from 'react-icons/bi';
import { IoDocumentsOutline, IoNewspaperOutline } from 'react-icons/io5';
import { GiBrazil } from 'react-icons/gi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import * as styles from './style';
import useOrder from '../../store/Order';
import { PopUpWarn } from '../../Popups';
import useUser from '../../store/User';

export default function DocumentsDefault() {
  const [orderFilter, setOrderFilter] = useState([]);
  const [orderFilterOption, setOrderFilterOption] = useState('');
  const [nameOrder, setNameOrder] = useState('');
  const [peopleId, setPeopleId] = useState(1);
  const {
    fetchOrders, order, orderDefault, setOrderDefault, setOrderCart, orderCart,
  } = useOrder();
  const { user } = useUser();

  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const operations = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Registro Civil', icon: <GrDocumentUser />, value: 'registro-civil' },
    { name: 'Registro de Imóveis', icon: <BiHomeAlt2 size="20px" />, value: 'registro-de-imoveis' },
    { name: 'Notas', icon: <IoNewspaperOutline />, value: 'notas' },
    { name: 'Protesto', icon: <TbHammer />, value: 'protesto' },
    { name: 'Federais', icon: <GiBrazil />, value: 'federais' },
    { name: 'Estaduais', icon: <GiBrazil />, value: 'estaduais' },
    { name: 'Municipais', icon: <GiBrazil />, value: 'municipais' },
  ];

  // Cria uma cópia do array sem o primeiro elemento para renderizar
  const orderDefaultForRender = orderDefault.slice(1);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (orderDefault.length > 0) {
      const no = orderDefault[0]; // Acessa o primeiro elemento e pega a propriedade nameOrder
      setNameOrder(no);
    }
  }, [orderDefault]);

  useEffect(() => {
    if (order) {
      const orderFilterList = order.filter((od) => od.type === 'order');
      setOrderFilter(orderFilterList);
    }

    if (orderFilterOption) {
      const orderFilterListOption = order.filter((od) => od.type === 'order' && od.selectedOption === orderFilterOption);
      setOrderFilter(orderFilterListOption);
    }
  }, [order, orderFilterOption]);

  const handleOrder = (id) => {
    if (peopleId === null) {
      PopUpWarn('Selecione uma pessoa primeiro');
      return;
    }

    const thisId = peopleId + 1;
    const updatedOrderDefault = orderDefault.map((ord) => {
      if (ord.id === thisId) {
        const isSelected = ord.orderSelect.some((od) => od.id === id);

        if (isSelected) {
          const updatedOrderSelect = ord.orderSelect.filter((od) => od.id !== id);
          return { ...ord, orderSelect: updatedOrderSelect };
        }
        const orderId = orderFilter.find((od) => od.id === id);
        if (orderId) {
          return { ...ord, orderSelect: [...ord.orderSelect, { ...orderId }] };
        }
      }
      return ord;
    });

    setOrderDefault(updatedOrderDefault);
  };

  const handleSelect = async () => {
    const isAnyOrderSelectEmpty = orderDefaultForRender.some((ord) => ord.orderSelect.length === 0);

    if (isAnyOrderSelectEmpty) {
      PopUpWarn('Cada pessoa deve ter pelo menos um documento selecionado');
    } else {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
      const year = today.getFullYear();
      const formatDate = `${day}/${month}/${year}`;

      const data = [...(orderCart || []),
        {
          nameOrder,
          userUid: user.uid,
          userDoc: user.doc,
          date: today,
          dateFormat: formatDate,
          users: orderDefaultForRender,
        },
      ];

      setOrderCart(data);
      navigate('/pending');
    }
  };

  const searchIcon = (icon) => {
    switch (icon) {
      case 'registro-civil':
        return <GrDocumentUser />;
      case 'notas':
        return <IoNewspaperOutline />;
      case 'registro-de-imoveis':
        return <BiHomeAlt2 />;
      case 'protesto':
        return <TbHammer />;
      case 'federais':
        return <GiBrazil />;
      case 'estaduais':
        return <GiBrazil />;
      case 'municipais':
        return <GiBrazil />;
      default:
        return null;
    }
  };

  const isSelected = (id) => {
    const currentPerson = orderDefaultForRender.find((ord) => ord.id === peopleId + 1);
    return currentPerson ? currentPerson.orderSelect.some((od) => od.id === id) : false;
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <styles.DocumentSt>
          <Title text="Solicitar um documento" url="requestdocument" icon={<TiDocumentText />} />

          <h3>{nameOrder}</h3>

          <styles.DocumentSection>

            <styles.DocumentGroupPeople>
              {orderDefaultForRender && orderDefaultForRender.map((ord) => (
                <styles.DocumentPeople key={ord.id} $isPeople={peopleId} onClick={() => setPeopleId(ord.id - 1)}>

                  <div>
                    <p>
                      Documento:
                      <b>{ord.docs}</b>
                    </p>
                    <p>
                      Nome:
                      <b>{ord.name}</b>
                    </p>
                  </div>

                  <div>
                    <p>
                      Estado:
                      <b>{ord.state}</b>
                    </p>
                    <p>
                      Cidade:
                      <b>{ord.city}</b>
                    </p>
                  </div>
                </styles.DocumentPeople>
              ))}
            </styles.DocumentGroupPeople>

            <styles.DocumentSelect>
              <styles.DocumentRequest>
                <h3>Selecione qual documento deseja</h3>

                <styles.DocumentSelection ref={scrollRef}>

                  <button type="button" onClick={() => setOrderFilterOption('suggestions')}>
                    <span>Sugestões</span>
                  </button>

                  <button type="button" onClick={() => setOrderFilterOption('kits')}>
                    <IoDocumentsOutline />
                    <span>Kits</span>
                  </button>
                  {operations.map((op) => (
                    <button type="button" key={op.value} onClick={() => setOrderFilterOption(op.value)}>
                      {op.icon}
                      <span>{op.name}</span>
                    </button>
                  ))}
                </styles.DocumentSelection>

                <styles.DocumentGroupInput>
                  {orderFilter && orderFilter.map((od) => (
                    <styles.DocumentInput
                      key={od.id}
                      id={od.id}
                      onClick={() => handleOrder(od.id)}
                      className={isSelected(od.id) ? 'input_select' : ''}
                    >
                      {searchIcon(od.selectedOption)}
                      <span>{od.name}</span>

                      <button type="button">
                        <GrRadialSelected />
                      </button>
                    </styles.DocumentInput>
                  ))}
                </styles.DocumentGroupInput>
              </styles.DocumentRequest>
            </styles.DocumentSelect>

            <styles.DocumentSend className="box_shadow">
              <button type="button" onClick={handleSelect}>
                Continuar
              </button>
            </styles.DocumentSend>

          </styles.DocumentSection>

        </styles.DocumentSt>
      </Center>
    </article>
  );
}
