// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCjSQ28dFnjthAuE7KqQ9Hg4gbsvnFr16E',
  authDomain: 'jk-bd-oficial.firebaseapp.com',
  projectId: 'jk-bd-oficial',
  storageBucket: 'jk-bd-oficial.appspot.com',
  messagingSenderId: '313081971499',
  appId: '1:313081971499:web:b9f2f2aacac0a7722dfb6e',
  measurementId: 'G-L0YF6XHCQ0',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
