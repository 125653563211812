import styled from 'styled-components';

export const HeaderSt = styled.header`
  min-height: 100%;
  height: auto;
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 20px;
  background-color: ${(props) => props.theme.backgroundHover};

  img{
    width: 100%;
    max-width: 170px;
  }

  button{
    margin-top: auto;
    margin-right: auto;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textDark};
    cursor: pointer;
    
    svg{
      font-size: 20px;
      margin-top: -2px;
    }

    span{
      font-size: 16px;
      margin-left: 5px;
    }

    &:hover{
      transition: 0.7s;

      svg,span{
        color: ${(props) => props.theme.layout};
      }
    }
  }
`;

export const HeaderRequest = styled.div`

  width: 100%;

  a{
  width: 100%;
  background-color: ${(props) => props.theme.layout};
  color: ${(props) => props.theme.textLigth};
  padding: 14px 5px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  cursor: pointer;

  span{
    font-size: 16px;
    font-weight: 400;
  }

  svg{
    font-size: 16px;
    margin-right: 6px;
    margin-top: 2px;
  }

  &:hover{
    transition: 0.7s;
    background-color: ${(props) => props.theme.layoutHover};
  }
  }
`;

export const HeaderUl = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin-top: 20px;

  li{
    width: 100%;
    margin-top: 15px;

    a{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      padding: 7px 0;

      svg{
        margin-right: 8px;
      }

      &:hover{
        color: ${(props) => props.theme.layout};
        transition: 0.7s;
      }
    }
  }
`;
