import React from 'react';
import { IoClose } from 'react-icons/io5';
import * as styles from '../EditAppl/style';

export default function InfoAppl({ func, obj }) {
  const {
    docs, name, nameOrder, orderSelect, state,
  } = obj;

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>

        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Informações do pedido</h2>

        <styles.ListForm>
          <li>
            Nome do pedido:
            <b>{nameOrder}</b>
          </li>

          <li>
            Documento do solicitado:
            <b>{docs}</b>
          </li>

          <li>
            Nome do solicitado:
            <b>{name}</b>
          </li>

          <li>
            Preço:
            <b>{orderSelect.reduce((total, item) => total + parseFloat(item.prince), 0).toFixed(2).replace('.', ',')}</b>
          </li>

          <li>
            Estado:
            <b>{state}</b>
          </li>

          <li>
            Documentos solictados
            <br />
            {orderSelect.map((os) => (
              <>
                <b>{os.name}</b>
                {' '}
                <br />
              </>
            ))}
          </li>
        </styles.ListForm>

      </styles.FormOrderForm>

    </styles.FormOrderSt>
  );
}
