import React from 'react';
import * as styles from './style';

export default function Center({ children, class1 = '' }) {
  return (
    <styles.CenterSt className={`center ${class1}`}>
      {children}
    </styles.CenterSt>
  );
}
