import styled from 'styled-components';

export const SettingsSt = styled.ul`
  display: flex;
  list-style: none;
  list-style-position: inside;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 15px;
  border-radius: 0.5rem 0.5rem 0 0;

  a{
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
    text-align: center;
  }
`;

export const SettingsAccount = styled.div`
  border-radius: 0 0 0.5rem 0.5rem ;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 15px;

  h3{
    font-size: 18px;
    font-weight: 500;
  }

  button{
    margin-top: 30px;
    font-size: 16px;
    padding: 7px;
    width: 100%;
    cursor: pointer;
    max-width: 250px;
    background-color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.layout};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.textLigth};

    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;

export const SettingInputs = styled.div`
  display: flex;
  justify-content: space-between;

  div{
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    input{
      width: 100%;
      padding: 7px 5px;
      font-size: 16px;
      background-color: ${(props) => props.theme.background};
      border-radius: 0.2rem;
      margin-top: 5px;
      border: 1px solid ${(props) => props.theme.textDark};
      color: ${(props) => props.theme.textDark};

      &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
