import styled from 'styled-components';

export const Sing = styled.section`
  height: 100%;
  display: flex;
`;

export const SingBanner = styled.div`
  width: 50%;
  min-height: 100%;
  background-image: url(${(props) => props.$isBanner});
  background-position: center;
  background-size: cover;
`;

export const SingInfo = styled.div`
  width: 50%;
  min-height: 100%;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2{
    font-size: 24px;
    /* color: ${(props) => props.theme.text}; */
  }

  > span{
    font-size: 16px;
    margin-top: 2px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
  }

  h3{
    font-size: 16px;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  a{
    color: ${(props) => props.theme.layout};
    margin-top: 5px;
    font-size: 16px;
  }
`;

export const SingForm = styled.form`
  width: 100%;
  text-align: center;

  > button{
    font-size: 18px;
    padding: 9px 5px;
    width: 80%;
    margin-top: 35px;
    margin-bottom: 30px;
    background-color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};
    cursor: pointer;
    border-radius: 0.5rem;

    &:hover{
      background-color: ${(props) => props.theme.layoutHover};
      transition: 0.7s;
    }
  }
`;

export const SingGroupInput = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span{
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
  }

  input{
    width: 100%;
    font-size: 16px;
    padding: 12px 8px;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.textDark};
  }
`;

export const SingGroupInputRow = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div{
    width: 48%;
    display: flex;
    flex-direction: column;
  }

  span{
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
    text-align: left;
  }

  input{
    width: 100%;
    font-size: 16px;
    padding: 12px 8px;
    border-radius: 0.5rem;
    border: 1px solid ${(props) => props.theme.textDark};
  }
`;

export const SingPasswordRules = styled.ul`
  width: 100%;
  margin-top: 15px;
  padding-left: 15px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
`;

export const SingGroupBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div{
    display: flex;
    align-items: center;

    input{
      padding: 10px;
      margin-right: 5px;
      height: 16px;
      width: 20px;
      cursor: pointer;
    }

    span{
      font-size: 14px;
    }
  }

  p{
    font-size: 14px;
    color: ${(props) => props.theme.layout};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SingVerif = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0 20px 0;

  button:nth-child(${(props) => (props.$isVerif ? 1 : 2)}){
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};

    transition: 0.7s;
  }

  button{
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    padding: 7px 10px;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    cursor: pointer;
    border: 0;
    margin-right: 5px;
    background-color: transparent;
  }
`;

export const SingWarn = styled.div`
  width: 100%;
  padding: 0px 0 5px 0;
  border-radius: 0.5rem;
  background-color: rgba(255, 0, 0, 0.8);
  color: ${(props) => props.theme.textLigth};
  border: 1px solid ${(props) => props.theme.layout};
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    font-size: 24px;
    margin-bottom: -6px;
  }

  h3{
    font-size: 16px;
    margin-left: 2px;
  }
`;
