import styled from 'styled-components';

export const FinancialSt = styled.section`
  display: flex;
  flex-direction: column;

  h3{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
    margin-bottom: 10px;
  }
`;

export const FinancialValue = styled.div`
  padding: 15px;
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 30px;
  margin-left: auto;
  border: 2px solid ${(props) => props.theme.layout};
  
  h4{
    color: ${(props) => props.theme.layout};
    font-size: 18px;
    font-weight: 300;

    b{
      margin-left: 10px;
      font-size: 24px;
    }
  }

  button{
    width: 100%;
    font-size: 16px;
    padding: 5px;
    border: 1px solid ${(props) => props.theme.layout};
    color: ${(props) => props.theme.layout};
    border-radius: 0.2rem;
    cursor: pointer;
    margin-top: 15px;

    &:hover{
      transition: 0.7s;
      color: ${(props) => props.theme.textLigth};
      background-color: ${(props) => props.theme.layout};
    }
  }
`;
