import React from 'react';
import { useLocation } from 'react-router-dom';

export default function Success() {
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    const paymentId = query.get('payment_id');
    const preferenceId = query.get('preference_id');
    const paymentType = query.get('payment_type');

    console.log('Status:', status);
    console.log('Payment ID:', paymentId);
    console.log('Preference ID:', preferenceId);
    console.log('Payment Type:', paymentType);

    // Aqui você pode adicionar lógica adicional, como salvar a compra no banco de dados, etc.
  }, [location]);

  alert('oi');

  return (
    <h2>oi</h2>
  );
}
