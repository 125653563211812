import React, { useEffect, useState } from 'react';
import { GoFileDirectory } from 'react-icons/go';
import { Link, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import * as styles from './style';
import { DashboardSt } from '../Dashboard/style';
import useApplication from '../../store/Application';
import useUser from '../../store/User';

export default function MyRequestId() {
  const [appl, setAppl] = useState([]);
  const { fetchApplication, application } = useApplication();
  const { user } = useUser();
  const { id: urlId } = useParams();

  useEffect(() => {
    fetchApplication(user.uid);
  }, []);

  useEffect(() => {
    if (application) {
      const ap = application.filter((el) => el.id === urlId);

      setAppl(ap[0]);

      console.log(ap[0]);
    }
  }, [application]);

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Detalhes do pedidos" url={`myrequest/${urlId}`} icon={<GoFileDirectory />} />

          {appl && (
            <styles.MyRequestId className="box_shadow">

              <Link to="/myrequest"><IoMdArrowRoundBack /></Link>

              <h2>{appl.nameOrder}</h2>

              <styles.MyRequestIdInfo>
                <h4>Detalhe do seu pedido</h4>

                <ul>
                  <li>
                    Cidade:
                    {appl.city}
                  </li>

                  <li>
                    Data:
                    {appl.dateFormat}
                  </li>

                  <li>
                    Documento:
                    {appl.docs}
                  </li>

                  <li>
                    Número do pedido:
                    {appl.numberId}
                  </li>

                  <li>
                    Estado:
                    {appl.state}
                  </li>
                </ul>
              </styles.MyRequestIdInfo>
            </styles.MyRequestId>
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
