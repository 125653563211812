import React, { useEffect, useState } from 'react';
import { TbSettings } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { DashboardSt } from '../Dashboard/style';
import * as styles from './style';
import useUser from '../../store/User';
import { PopUpSuccess, PopUpWarn } from '../../Popups';
import { db } from '../../service/firebaseConnection';

export default function Settings() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [docs, setDoc] = useState('');
  const { user } = useUser();

  useEffect(() => {
    setEmail(user.email);
    setName(user.name);
    setTel(user.tel);
    setDoc(user.doc);
    console.log(user);
  }, [user]);

  const links = [
    { name: 'Conta', url: '/settings' },
  ];

  const handleUpdate = async () => {
    if (user) {
      try {
        updateDoc(doc(db, 'users', user.uid), {
          name,
          tel,
        });
        PopUpSuccess('Usuário atualizado com successo');
      } catch (e) {
        PopUpWarn('Erro ao atualizar as informações do usuário.');
      }
    }
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>

          <Title text="Configurações da conta" url="settings" icon={<TbSettings />} />

          <styles.SettingsSt>
            {links.map((link) => (
              <li><Link to={link.url}>{link.name}</Link></li>
            ))}
          </styles.SettingsSt>

          <styles.SettingsAccount>
            <h3>Dados da conta</h3>

            <styles.SettingInputs>
              <div>
                <span>Email</span>
                <input type="text" value={email} disabled />
              </div>

              <div>
                <span>Documento</span>
                <input type="text" value={docs} disabled />
              </div>
            </styles.SettingInputs>

            <styles.SettingInputs>
              <div>
                <span>Nome</span>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div>
                <span>Telefone</span>
                <input type="text" value={tel} onChange={(e) => setTel(e.target.value)} />
              </div>
            </styles.SettingInputs>

            <button type="button" onClick={handleUpdate}>Atualizar</button>
          </styles.SettingsAccount>
        </DashboardSt>
      </Center>
    </article>
  );
}
