import React, { useEffect, useState } from 'react';
import { GoFileDirectory } from 'react-icons/go';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FaCartShopping } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { DashboardSt } from '../Dashboard/style';
import * as styles from './style';
import useApplication from '../../store/Application';
import useUser from '../../store/User';
import { PendingClear } from '../Pending/style';

export default function MyRequest() {
  const [applPagament, setApplPagament] = useState([]);
  const [number, setNumber] = useState(1);
  const [filterNumber, setFilterNumber] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [show, setShow] = useState(false);
  const { user } = useUser();
  const { fetchApplication, application } = useApplication();

  useEffect(() => {
    fetchApplication(user.uid);
  }, []);

  useEffect(() => {
    if (application) {
      const ap = application.filter((el) => el.pagament === 'approved');

      setApplPagament(ap);
    }
  }, [application]);

  const handleFilterNumberChange = (e) => setFilterNumber(e.target.value);
  const handleFilterNameChange = (e) => setFilterName(e.target.value);
  const handleFilterDateChange = (e) => setFilterDate(e.target.value);
  const handleFilterValueChange = (e) => setFilterValue(e.target.value);

  const filteredData = applPagament.filter((apl) => (
    (!filterNumber || apl.numberId.toString().includes(filterNumber))
      && (!filterName || apl.nameOrder.toLowerCase().includes(filterName.toLowerCase()))
      && (!filterDate || apl.dateFormat.includes(filterDate))
      && (!filterValue || parseFloat(apl.prince).toFixed(2).includes(filterValue))
      && (!selectedStatus || apl.status === selectedStatus)
  ));
  const handleApproved = () => {
    const ap = application.filter((el) => el.pagament === 'approved');

    setApplPagament(ap);
    setNumber(1);
  };

  const handlePending = () => {
    const ap = application.filter((el) => el.pagament !== 'approved');

    setApplPagament(ap);
    setNumber(2);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? '' : status));
  };

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  const changeIcon = (status) => {
    switch (status) {
      case 'pending':
        return 'icon_access_false';

      case 'progress':
        return 'icon_access_progress';

      default:
        return 'icon_access_true';
    }
  };

  console.log(application);

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Meus pedidos" url="myrequest" icon={<GoFileDirectory />} />

          <styles.MyRequestPagament $isNumber={number}>
            <button type="button" onClick={handleApproved}>
              Pedidos aprovados
            </button>

            <button type="button" onClick={handlePending}>
              Pedidos aguardando pagamento
            </button>
          </styles.MyRequestPagament>

          <styles.MyRequestOperations>
            <styles.MyRequestOperation
              $isYellow
              className={selectedStatus === 'progress' ? 'selected' : ''}
              onClick={() => handleStatusClick('progress')}
            >
              <p>Processando</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'progress' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>

            <styles.MyRequestOperation
              $isGreen
              className={selectedStatus === 'finish' ? 'selected' : ''}
              onClick={() => handleStatusClick('finish')}
            >
              <p>Finalizados</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'finish' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>

            <styles.MyRequestOperation
              className={selectedStatus === 'pending' ? 'selected' : ''}
              onClick={() => handleStatusClick('pending')}
            >
              <p>Pendentes</p>
              <h2>
                {application && (
                  application.reduce((acc, order) => (order.status === 'pending' ? acc + 1 : acc), 0)
                )}
              </h2>
            </styles.MyRequestOperation>
          </styles.MyRequestOperations>

          {applPagament && (
            applPagament.length > 0 && (
              <styles.MyRequestFilter>
                <button type="button" onClick={() => setShow(!show)}>
                  Filtro

                  {show ? <IoIosArrowDown /> : <IoIosArrowUp /> }

                </button>

                {show && (
                  <styles.MyRequestFilterGroup>
                    <styles.MyRequestFilterInput>
                      <span>Número do pedido</span>
                      <input
                        type="number"
                        placeholder="Número do pedido"
                        value={filterNumber}
                        onChange={handleFilterNumberChange}
                      />
                    </styles.MyRequestFilterInput>

                    <styles.MyRequestFilterInput>
                      <span>Nome do pedido</span>
                      <input
                        type="text"
                        placeholder="Nome do pedido"
                        value={filterName}
                        onChange={handleFilterNameChange}
                      />
                    </styles.MyRequestFilterInput>

                    <styles.MyRequestFilterInput>
                      <span>Data do pedido</span>
                      <input
                        type="text"
                        placeholder="Data do pedido"
                        value={filterDate}
                        onChange={handleFilterDateChange}
                      />
                    </styles.MyRequestFilterInput>

                    <styles.MyRequestFilterInput>
                      <span>Valor do pedido</span>
                      <input
                        type="text"
                        placeholder="Valor do pedido"
                        value={filterValue}
                        onChange={handleFilterValueChange}
                      />
                    </styles.MyRequestFilterInput>
                  </styles.MyRequestFilterGroup>
                )}

              </styles.MyRequestFilter>
            )
          )}

          {applPagament && (
            applPagament.length > 0 && (
              <styles.MyRequestTables>
                <thead>
                  <tr>
                    <th>N.º do pedido</th>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>

                  {filteredData.map((apl) => (
                    <tr>
                      <td>{apl.numberId}</td>
                      <td>{apl.nameOrder}</td>
                      <td>{apl.dateFormat}</td>
                      <td>{parseFloat(apl.prince).toFixed(2).replace('.', ',')}</td>
                      <td>
                        <span className={changeCheck(apl.status)}>
                          {apl.status === 'pending' ? 'Pendente' : ''}
                          {apl.status === 'progress' ? 'Em andamento' : ''}
                          {apl.status === 'finish' ? 'Finalizado' : ''}
                        </span>
                      </td>
                      <Link to={`/myrequest/${apl.id}`}><HiOutlineDocumentReport className={changeIcon(apl.status)} size={26} /></Link>
                    </tr>
                  ))}

                </tbody>
              </styles.MyRequestTables>
            )
          )}

          {applPagament && (
            applPagament.length <= 0 && (
              <PendingClear>
                <FaCartShopping />
                <h3>Sem pedidos de pagamento aprovado</h3>

                <span>Realize um pedido, entre e selecione uma das opções e efetue seu pedido</span>

                <Link to="/request">Realize um pedido agora mesmo</Link>
              </PendingClear>
            )
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
