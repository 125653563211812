import styled from 'styled-components';

export const FormSt = styled.form`
  h3{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
    margin-bottom: 10px;
  }

  .send_form{
    width: 100%;
    font-size: 16px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};
    padding: 12px 5px;
    border-radius: 0.5rem;
    display: flex;
    cursor: pointer;
    border: 0;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    
    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;

export const FormFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  h4{
    font-size: 18px;
    margin-top: 20px;
  }
`;

export const FormOrders = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 15px;
  background-color: ${(props) => props.theme.backgroundHover};
`;

export const FormState = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  div{
    width: 48%;
    display: flex;
    flex-direction: column;
  }

  select{
    width: 100%;
    font-size: 18px;
    padding: 3px 6px;
    margin-top: 10px;
    background-color: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 0.2rem;
    color: ${(props) => props.theme.textDark};
    cursor: pointer;
  }
`;

export const FormOrdersTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backgroundHover};
  margin-bottom: 5px;

  div{
    display: flex;
    align-items: center;
    button.btn_delete{
      margin-right: 10px;
      svg{
        font-size: 20px;
        color: #F5190A;
      }
    }
  }

  span{
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.textDark};
  }

  button{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    
    svg{
      font-size: 26px;
      color: ${(props) => props.theme.textDark};
    }
  }
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span{
    font-size: 16px;
  }

  input{
    width: 100%;
    font-size: 16px;
    border: 1px solid ${(props) => props.theme.textDark};
    font-family: "Plus Jakarta Sans", sans-serif;
    border-radius: 0.2rem;
    padding: 8px 10px;
    margin-top: 3px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.textDark};
  }

`;

export const FormAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  font-size: 15px;
  border: 1px solid ${(props) => props.theme.layout};
  cursor: pointer;
  color: ${(props) => props.theme.layout};
  padding: 10px;
  background-color: ${(props) => props.theme.background};
  margin-top: 15px;
  border-radius: 0.2rem;

  svg{
    margin-right: 5px;
  }


  &:hover{
    transition: 0.7s;
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};
  }
`;

export const FormVerif = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;

  button{
    width: 100%;
    max-width: 150px;
    text-transform: uppercase;
    color: ${(props) => props.theme.textDark};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-right: 10px;
    padding: 5px;
    font-size: 16px;

    &:nth-child(1){
      ${(props) => (props.$isVerif ? `border-bottom: 2px solid ${props.theme.layout};` : '')}
    }

    &:nth-child(2){
      ${(props) => (props.$isVerif === false ? `border-bottom: 2px solid ${props.theme.layout};` : '')}
    }
    
  }
`;

export const FormInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 20px;
  background-color: ${(props) => props.theme.backgroundHover};

  h4{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const FormInfosPeople = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};

  h5{
    font-size: 16px;
    margin-bottom: 10px;
  }

  div{
    width: 100%;
    display: flex;
    align-items: center;

    p{
      width: 50%;

      b{
        margin-left: 5px;
      }
    }
  }

`;
