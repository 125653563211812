import { create } from 'zustand';
import { collection, getDocs, where } from 'firebase/firestore';
import { db } from '../service/firebaseConnection';

const useOrder = create((set) => ({
  orders: [],

  setOrderSelect: (order = []) => {
    set({ orderSelect: order });
  },

  fetchOrderCart: () => {
    const local = localStorage.getItem('@JK-pending');

    if (local) {
      const orderData = JSON.parse(local);
      if (orderData) {
        set({ orderCart: orderData });
      }
    } else {
      set({ orderCart: [] });
    }
  },

  setOrderCart: (order = []) => {
    localStorage.setItem('@JK-pending', JSON.stringify(order));

    set({ orderCart: order });
  },

  fetchOrders: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'orders'));

      const ordersList = [];

      querySnapshot.forEach((doc) => {
        ordersList.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      set({ order: ordersList });
    } catch (error) {
      console.log(error);
    }
  },

  setOrderKits: (kits = []) => {
    set({ kits });
  },

  fetchOrderKits: async (userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'kits'), where('userUid', '==', userId));

      const ordersKits = [];

      querySnapshot.forEach((doc) => {
        ordersKits.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      set({ kits: ordersKits });
    } catch (error) {
      console.log(error);
    }
  },

  setOrderDefault: (orderDefault = []) => {
    set({ orderDefault });
  },
}));

export default useOrder;
