import React, { useEffect, useState } from 'react';
import { IoClose, IoHomeSharp, IoNewspaperOutline } from 'react-icons/io5';
import { GrDocumentConfig, GrDocumentUser, GrRadialSelected } from 'react-icons/gr';
import { BiHomeAlt2 } from 'react-icons/bi';
import { TbHammer } from 'react-icons/tb';
import { GiBrazil } from 'react-icons/gi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { FaCar } from 'react-icons/fa6';
import { FormOrderForm, FormOrderSt } from '../EditAppl/style';
import useOrder from '../../store/Order';
import {
  DocumentInput, DocumentVerif, EditGroupInput, EditSelection,
} from '../../pages/Documents/style';
import { PopUpWarn } from '../../Popups';

export default function EditOrderSelect({ func, obj, setObj }) {
  const [orderFilter, setOrderFilter] = useState([]);
  const [orderSelect, setOrderSelect] = useState(obj);
  const [orderFilterOption, setOrderFilterOption] = useState('');
  const [verif, setVerif] = useState('order');

  const {
    order, fetchOrders,
  } = useOrder();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (order) {
      const hasOrderType = order.some((od) => od.type === verif);

      if (hasOrderType) {
        const orderFilterList = order.filter((od) => od.type === verif);

        setOrderFilter(orderFilterList);
      }

      if (orderFilterOption) {
        const orderFilterList = order.filter((od) => od.type === verif);
        const orderFilterListOption = orderFilterList.filter((od) => od.selectedOption === orderFilterOption);

        setOrderFilter(orderFilterListOption);
      }
    }
  }, [order, orderFilterOption, verif]);

  const operations = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Registro Civil', icon: <GrDocumentUser />, value: 'registro-civil' },
    { name: 'Registro de Imóveis', icon: <BiHomeAlt2 size="20px" />, value: 'registro-de-imoveis' },
    { name: 'Notas', icon: <IoNewspaperOutline />, value: 'notas' },
    { name: 'Protesto', icon: <TbHammer />, value: 'protesto' },
    { name: 'Federais', icon: <GiBrazil />, value: 'federais' },
    { name: 'Estaduais', icon: <GiBrazil />, value: 'estaduais' },
    { name: 'Municipais', icon: <GiBrazil />, value: 'municipais' },
  ];

  const operationsSearch = [
    { name: 'Todos', icon: <AiOutlineGlobal />, value: '' },
    { name: 'Pessoa/Empresa', icon: <FiUser />, value: 'pessoas-empresa' },
    { name: 'Débitos/Pendências', icon: <GrDocumentConfig />, value: 'debitos-pendencias' },
    { name: 'Bens Móveis', icon: <FaCar />, value: 'bens-moveis' },
    { name: 'Bens Móveis', icon: <IoHomeSharp />, value: 'bens-imoveis' },
  ];

  const handleOrder = (id) => {
    console.log(orderSelect);
    const isSelected = orderSelect.filter((od) => od.id === id);

    if (isSelected) {
      if (isSelected.length > 0) {
        const updatedOrderSelect = orderSelect.filter((od) => od.id !== id);

        console.log(updatedOrderSelect);
        setOrderSelect(updatedOrderSelect);
      } else {
        const orderId = orderFilter.filter((od) => od.id === id);

        const dataOrder = orderSelect.map((od) => od);

        console.log(dataOrder);
        dataOrder.push(orderId[0]);
        console.log(dataOrder);

        if (orderId) {
          setOrderSelect(dataOrder);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (orderSelect.length > 0) {
      setObj(orderSelect);
      func();
    } else {
      PopUpWarn('Nenhuma solicitação selecionada');
    }
  };

  const searchIcon = (icon) => {
    switch (icon) {
      case 'registro-civil':
        return <GrDocumentUser />;
      case 'notas':
        return <IoNewspaperOutline />;
      case 'registro-de-imoveis':
        return <BiHomeAlt2 />;
      case 'protesto':
        return <TbHammer />;
      case 'federais':
        return <GiBrazil />;
      case 'estaduais':
        return <GiBrazil />;
      case 'municipais':
        return <GiBrazil />;
      case 'pessoas-empresa':
        return <FiUser />;
      case 'debitos-pendencias':
        return <GrDocumentConfig />;
      case 'bens-moveis':
        return <FaCar />;
      case 'bens-imoveis':
        return <IoHomeSharp />;
      default:
        return null;
    }
  };

  const isSelected = (id) => (orderSelect ? orderSelect.some((od) => od.id === id) : false);

  return (
    <FormOrderSt>
      <FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Editar solicitações</h2>

        <DocumentVerif $isVerif={verif !== 'search'}>
          <button type="button" onClick={() => setVerif('order')}>Certidões</button>
          <button type="button" onClick={() => setVerif('search')}>Pesquisas</button>
        </DocumentVerif>

        {verif === 'order' && (
          <EditSelection>
            {operations.map((op) => (
              <button type="button" onClick={() => setOrderFilterOption(op.value)}>
                {op.icon}
                <span>{op.name}</span>
              </button>
            ))}
          </EditSelection>
        )}

        {verif !== 'order' && (
          <EditSelection>
            {operationsSearch.map((op) => (
              <button type="button" onClick={() => setOrderFilterOption(op.value)}>
                {op.icon}
                <span>{op.name}</span>
              </button>
            ))}
          </EditSelection>
        )}

        <EditGroupInput>

          {orderFilter && (

            orderFilter.map((od) => (
              <DocumentInput
                id={od.id}
                onClick={() => handleOrder(od.id)}
                className={isSelected(od.id) ? 'input_select' : ''}
              >
                {searchIcon(od.selectedOption)}
                <span>{od.name}</span>

                <button type="button">
                  <GrRadialSelected />
                </button>
              </DocumentInput>
            ))

          )}
        </EditGroupInput>

        <button type="button" className="button_submit" onClick={handleSubmit}>Editar</button>
      </FormOrderForm>

    </FormOrderSt>

  );
}
