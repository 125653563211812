import styled from 'styled-components';

export const PendingDocumentSt = styled.section`
  padding: 30px 0 20px 0;
  height: 100%;

  .hidenn{
    padding: 50px;
  }
`;

export const PendingSt = styled.div`
  
  h3{
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  span{
    font-size: 16px;
    font-weight: 400;
  }
`;

export const PendingClear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;

  svg{
    font-size: 40px;
  }

  h3, h6{
    font-size: 20px;
    font-weight: 600;
    margin-top: 8px;
  }

  span{
    max-width: 50%;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
  }

  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 0.5rem;
    font-size: 16px;
    color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.layout};

    svg{
      font-size: 12px;
      margin-right: 5px;
    }

    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layout};
      color: ${(props) => props.theme.textLigth};
    }
  }
`;

export const PendingSend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  border-radius: 0.5rem;
  padding: 20px 25px 30px 25px;
  bottom: 0;
  left: 0;

  span{
    display: flex;
    align-items: center;
    font-size: 16px;

    b{
      font-size: 18px;
      margin-left: 5px;
    }
  }

  button{
    width: 100%;
    max-width: 230px;
    font-size: 16px;
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};
    padding: 12px 5px;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.textDark};
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;

export const PendingSendBuy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 0.5rem;
    font-size: 16px;
    color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.layout};

    svg{
      font-size: 12px;
      margin-right: 5px;
    }

    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layout};
      color: ${(props) => props.theme.textLigth};
    }
  }
`;

export const PendingTables = styled.table`
  width: 100%;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};
  padding-bottom: 100px;

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    font-weight: 300;
    text-align: center;

    div{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: auto;
      max-width: 70px;

      button:nth-child(1){
        background-color: #D7A800;
      }

      button:nth-child(2){
        background-color: #24A7FE;
      }
      button:nth-child(3){
        background-color: #F5190A;
      }

      .order{
        background-color: #00BD2C !important;
      }

      .hidden{
        display: none;
      }
    }

    img{
      width: 100%;
      max-width: 32px;
    }

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      border: 0;
      padding: 5px;
      border-radius: 0.2rem;
      color: ${(props) => props.theme.textLigth};
      cursor: pointer;
      
      svg{
        font-size: 18px;

      }
    }
  }
`;
