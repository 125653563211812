import styled from 'styled-components';

export const KitsSt = styled.section`
  margin-top: 30px;
`;

export const KitsButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    border-radius: 0.5rem;
    font-size: 16px;
    color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.layout};

    svg{
      font-size: 12px;
      margin-right: 5px;
    }

    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layout};
      color: ${(props) => props.theme.textLigth};
    }
  }
`;

export const KitsClear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  svg{
    font-size: 40px;
  }

  h3{
    font-size: 20px;
    font-weight: 600;
    margin-top: 8px;
  }

  span{
    max-width: 50%;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
  }
`;
