import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { doc, updateDoc } from 'firebase/firestore';
import * as styles from '../EditAppl/style';
import EditOrderSelect from '../EditOrderSelect';
import { PopUpWarn } from '../../Popups';
import useOrder from '../../store/Order';
import { db } from '../../service/firebaseConnection';

export default function EditKits({ func, obj }) {
  console.log(obj);

  const {
    name, orderSelect, id, userUid,
  } = obj;

  const [newOrderSelect, setNewOrderSelect] = useState(orderSelect);
  const [newName, setNewName] = useState(name);
  const [show, setShow] = useState(false);

  const { kits, setOrderKits } = useOrder();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newName !== '') {
      const data = {
        name: newName,
        orderSelect: newOrderSelect,
        userUid,
      };

      const listCart = kits.filter((oc) => oc.id !== id) || [];

      listCart.push({ ...data, id });

      try {
        const docRef = doc(db, 'kits', id);

        // Atualizar o documento com os novos dados
        await updateDoc(docRef, data);

        setOrderKits(listCart);
        func();
      } catch (error) {
        console.log(error);
      }
    } else {
      PopUpWarn('Um dos campos estão vazios!');
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Editar kit</h2>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>
            <span>Nome do pedido</span>
            <input type="text" placeholder="Digite o nome do pedido" value={newName} onChange={(e) => setNewName(e.target.value)} />
          </styles.OrderFormInput>
        </styles.OrderFormGroup>

        <styles.OrderFormGroup>
          <styles.OrderFormKitUl>
            <span>Documentos solicitandos</span>
            <ul>
              {newOrderSelect.map((os) => (
                <li>{os.name}</li>
              ))}

            </ul>
            <button type="button" onClick={() => setShow(true)}>Alterar solicitações</button>
          </styles.OrderFormKitUl>
        </styles.OrderFormGroup>

        <button type="button" className="button_submit" onClick={handleSubmit}>Editar</button>
      </styles.FormOrderForm>

      {show && (
        <EditOrderSelect func={() => setShow(false)} obj={newOrderSelect} setObj={setNewOrderSelect} />
      )}
    </styles.FormOrderSt>
  );
}
