import React from 'react';
import { Link } from 'react-router-dom';
import * as styles from './style';

export default function Document({
  url, title, text, subtitle, icon,
}) {
  return (
    <styles.DocumentSt>
      <Link to={url}>
        {icon}

        <p>{title}</p>
        <span>{text}</span>

        <p>{subtitle}</p>
      </Link>
    </styles.DocumentSt>
  );
}
