import React, { useEffect, useState } from 'react';
import { FaPencil, FaPlus } from 'react-icons/fa6';
import { IMaskInput } from 'react-imask';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import useOrder from '../../store/Order';
import useUser from '../../store/User';
import { PopUpWarn } from '../../Popups';

export default function Form() {
  const initialOrders = {
    docs: '',
    name: '',
    nameOrder: '',
    verif: false,
    show: true,
  };

  const [orders, setOrders] = useState([initialOrders]);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  const {
    orderSelect, setOrderCart, orderCart, fetchOrderCart,
  } = useOrder();
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderCart();

    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((response) => response.json())
      .then((data) => {
        setEstados(data);
      })
      .catch((error) => console.error('Error fetching estados:', error));
  }, []);

  useEffect(() => {
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
      .then((response) => response.json())
      .then((data) => {
        setCidades(data);
      })
      .catch((error) => {
        console.error('Error fetching cidades:', error);
        return [];
      });
  }, [state]);

  useEffect(() => {
    // console.log(orderSelect);
  }, [orderSelect]);

  const addOrder = () => {
    setOrders([...orders, { ...initialOrders }]);
  };

  const isOrderEmpty = (order) => {
    const fieldsToCheck = { ...order };
    delete fieldsToCheck.show; // Remove o campo 'show' da verificação
    delete fieldsToCheck.verif; // Remove o campo 'show' da verificação

    return Object.values(fieldsToCheck).some((value) => value === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = today.getFullYear();
    const formatDate = `${day}/${month}/${year}`;

    // Verificar se cidade e estado estão preenchidos
    if (city === '' || state === '') {
      PopUpWarn('Cidade e estado não podem estar vazios!');
      return;
    }

    // Verificar se algum dos pedidos tem campos vazios
    if (orders.some((order) => isOrderEmpty(order))) {
      PopUpWarn('Um dos campos dos pedidos está vazio!');
      return;
    }

    // Processar os pedidos se todas as verificações passarem
    const listCart = orderCart || [];

    orders.forEach((order) => {
      const data = {
        id: listCart.length + 1,
        userUid: user.uid,
        userDoc: user.cpf || user.cnpj,
        date: today,
        dateFormat: formatDate,
        state,
        city,
        ...order,
        orderSelect,
      };

      delete data.show; // Remove o campo 'show' da verificação
      delete data.verif; // Remove o campo 'verif' da verificação

      console.log(data);

      listCart.push(data);
    });

    setOrderCart(listCart);

    navigate('/pending');
  };

  const updateVerif = (index, newVerifValue) => {
    const updatedOrders = orders.map((order, i) => {
      if (i === index) {
        return { ...order, verif: newVerifValue };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const updateShow = (index, newVerifValue) => {
    const updatedOrders = orders.map((order, i) => {
      if (i === index) {
        return { ...order, show: newVerifValue };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const deleteOrder = (index) => {
    const updatedOrders = orders.filter((_, i) => i !== index);
    setOrders(updatedOrders);
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>

          <Title text="Preencha os dados do pedido" url="form" icon={<FaPencil />} />
          <styles.FormSt>
            <h3>Preencha os dados</h3>

            <styles.FormFlex>

              <styles.FormGroup>

                <styles.FormInput>
                  <span>Estado do documento</span>
                  <select value={state} onChange={(e) => setState(e.target.value)}>
                    <option selected />
                    {estados.map((estado) => (
                      <option key={estado.id} value={estado.sigla}>
                        {estado.nome}
                      </option>
                    ))}
                  </select>
                </styles.FormInput>

                <styles.FormInput>
                  <span>Cidade do documento</span>
                  <select value={city} onChange={(e) => setCity(e.target.value)}>
                    <option selected />
                    {cidades
                      && cidades.map((estado) => (
                        <option key={estado.id} value={estado.nome}>
                          {estado.nome}
                        </option>
                      ))}
                  </select>
                </styles.FormInput>

                <h4>Documentos da certidão</h4>

                {orders.map((order, index) => (
                  <styles.FormOrders>

                    <styles.FormOrdersTop>
                      <span>{order.nameOrder || 'Nome do pedido'}</span>

                      <div>
                        <button type="button" onClick={() => deleteOrder(index)}><MdDelete /></button>

                        {order.show ? (
                          <button type="button" onClick={() => updateShow(index, !order.show)}>
                            <IoMdArrowDropdown />
                          </button>
                        ) : (
                          <button type="button" onClick={() => updateShow(index, !order.show)}>
                            <IoMdArrowDropup />
                          </button>
                        )}
                      </div>

                    </styles.FormOrdersTop>

                    {order.show && (
                      <>
                        <styles.FormVerif $isVerif={order.verif}>
                          <button type="button" onClick={() => updateVerif(index, true)}>Cpf</button>
                          <button type="button" onClick={() => updateVerif(index, false)}>Cnpj</button>
                        </styles.FormVerif>

                        {order.verif ? (
                          <styles.FormInput>
                            <span>CPF</span>
                            <IMaskInput
                              mask="000.000.000-00"
                              type="text"
                              name="cpf"
                              placeholder="Digite seu CPF"
                              value={order.docs}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].docs = e.target.value;
                                setOrders(newOrders);
                              }}
                            />

                            <span>Nome completo</span>
                            <input
                              type="text"
                              name="name"
                              placeholder="Digite o nome completo"
                              value={order.name}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].name = e.target.value;
                                setOrders(newOrders);
                              }}
                            />
                          </styles.FormInput>
                        ) : (
                          <styles.FormInput>
                            <span>CNPJ</span>
                            <IMaskInput
                              mask="00.000.000/0000-00"
                              type="text"
                              name="cnpj"
                              placeholder="Digite seu CNPJ"
                              value={order.docs}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].docs = e.target.value;
                                setOrders(newOrders);
                              }}
                            />

                            <span>Nome da empresa</span>
                            <input
                              type="text"
                              name="name"
                              placeholder="Digite o nome da empresa"
                              value={order.name}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].name = e.target.value;
                                setOrders(newOrders);
                              }}
                            />
                          </styles.FormInput>
                        )}

                        <styles.FormInput>
                          <span>Nome do pedido</span>
                          <input
                            type="text"
                            name="order"
                            placeholder="Digite o nome do pedido"
                            value={order.nameOrder}
                            onChange={(e) => {
                              const newOrders = [...orders];
                              newOrders[index].nameOrder = e.target.value;
                              setOrders(newOrders);
                            }}
                          />
                        </styles.FormInput>
                      </>

                    )}
                  </styles.FormOrders>
                ))}

                <styles.FormAdd type="button" onClick={addOrder} className="addButton">
                  <FaPlus />
                  Adicionar Mais
                </styles.FormAdd>

                <button type="submit" className="send_form" onClick={handleSubmit}>Enviar</button>

              </styles.FormGroup>

              <styles.FormInfos>
                <h4>Dados do pedido</h4>

                <styles.FormInfosList>
                  <ul>
                    {orderSelect.map((od) => (
                      <li>{od.name}</li>
                    ))}
                  </ul>

                  {orders.map((order) => (
                    <h5>
                      Nome do pedido:
                      {' '}
                      <b>{order.nameOrder}</b>
                    </h5>
                  ))}

                  <p>
                    <div>
                      <FaRegCalendarAlt />
                      {' '}
                      Prazo Estimado:
                      {' '}
                    </div>

                    <span>
                      de
                      {' '}
                      <b>1</b>
                      {' '}
                      a
                      {' '}
                      <b>2</b>
                      {' '}
                      dias úteis
                    </span>
                  </p>

                  <span>
                    Valor do pedido:
                    <h6>
                      R$
                      {' '}
                      {orderSelect && (
                        orderSelect.reduce((total, item) => total + parseFloat(item.prince * orders.length), 0).toFixed(2).replace('.', ',')
                      )}
                    </h6>
                  </span>
                </styles.FormInfosList>

              </styles.FormInfos>

            </styles.FormFlex>

          </styles.FormSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
