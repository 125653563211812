import { create } from 'zustand';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { db } from '../service/firebaseConnection';

const useApplication = create((set) => ({
  applications: [],

  fetchApplication: async (userId) => {
    try {
      const q = query(collection(db, 'application'), where('userUid', '==', userId));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      set({ application: data });
    } catch (e) {
      console.error('Error fetching tasks: ', e);
    }
  },
}));

export default useApplication;
