import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RouteWrapper from './route';
import SingIn from '../pages/SignIn';
import SingUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import MyRequest from '../pages/MyRequest';
import Request from '../pages/Request';
import Kits from '../pages/Kits';
import Financial from '../pages/Financial';
import Help from '../pages/Help';
import Form from '../pages/Form';
import Pending from '../pages/Pending';
import DocumentsKits from '../pages/DocumentsKits';
import Search from '../pages/Search';
import Success from '../pages/Success';
import Settings from '../pages/Settings';
import MyRequestId from '../pages/MyRequestId';
import FormDefault from '../pages/FormDefault';
import DocumentsDefault from '../pages/DocumentsDefault';
import Development from '../pages/Development';

export default function RoutesComp() {
  return (
    <Routes>
      <Route path="/" element={<RouteWrapper loggedComponent={<Dashboard />} defaultComponent={<SingIn />} />} />

      <Route path="/register" element={<RouteWrapper loggedComponent={<Dashboard />} defaultComponent={<SingUp />} />} />

      <Route path="/dashboard" element={<RouteWrapper loggedComponent={<Dashboard />} defaultComponent={<SingIn />} IsPrivate Access />} />

      <Route path="/request" element={<RouteWrapper loggedComponent={<Request />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/myrequest" element={<RouteWrapper loggedComponent={<MyRequest />} defaultComponent={<SingIn />} IsPrivate Access />} />

      <Route path="/myrequest/:id" element={<RouteWrapper loggedComponent={<MyRequestId />} defaultComponent={<SingIn />} IsPrivate Access />} />

      <Route path="/success" element={<RouteWrapper loggedComponent={<Success />} defaultComponent={<SingIn />} IsPrivate Access />} />

      <Route path="/kits" element={<RouteWrapper loggedComponent={<Kits />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/financial" element={<RouteWrapper loggedComponent={<Financial />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/requestdocument" element={<RouteWrapper loggedComponent={<DocumentsDefault />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/requestsearch" element={<RouteWrapper loggedComponent={<Search />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/requestkit" element={<RouteWrapper loggedComponent={<DocumentsKits />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/help" element={<RouteWrapper loggedComponent={<Help />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/form" element={<RouteWrapper loggedComponent={<Form />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/formdefault" element={<RouteWrapper loggedComponent={<FormDefault />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/pending" element={<RouteWrapper loggedComponent={<Pending />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/settings" element={<RouteWrapper loggedComponent={<Settings />} defaultComponent={<SingIn />} IsPrivate />} />

      <Route path="/development" element={<RouteWrapper loggedComponent={<Development />} defaultComponent={<SingIn />} IsPrivate />} />

    </Routes>
  );
}
