import React from 'react';
import { FaPencil } from 'react-icons/fa6';
import { TiDocumentText } from 'react-icons/ti';
import { IoDocumentsOutline } from 'react-icons/io5';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import { MdDatasetLinked } from 'react-icons/md';
import { PiSuitcase } from 'react-icons/pi';
import Document from '../../components/Document';
import * as styles from './style';
import Title from '../../components/Title';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';

export default function Request() {
  return (
    <article className="article_flex">

      <Header />

      <Center>
        <DashboardSt>
          <Title text="Fazer pedido" url="request" icon={<FaPencil />} />

          <styles.RequestSt>
            <h3>Escolha a operação</h3>

            <styles.RequestGroup>
              <Document
                url="/formdefault"
                title="Solicitar um documento"
                subtitle="Solicite em formato físico ou digital - nós fazemos a busca e entregamos conforme sua escolha."
                text="As principais opções de documentos e certidões para atender sua necessidade."
                icon={<TiDocumentText />}
              />

              <Document
                url="/requestsearch"
                title="Fazer uma pesquisa"
                subtitle="Após a busca, você pode solicitar a emissão da documentação de que precisar."
                text="Pesquise documentos em milhares de instituições de todo o Brasil."
                icon={<HiOutlineMagnifyingGlass />}
              />

            </styles.RequestGroup>

            <styles.RequestGroup>
              <Document
                url="/development"
                title="Extração de Dados"
                subtitle="Faça upload de qualquer tipo de documento e extraia os dados mais importantes com a Extração de Dados."
                text="Extraia os campos mais importantes de seus documentos automaticamente."
                icon={<MdDatasetLinked />}
              />

              <Document
                url="/development"
                title="Solicitar uma Diligência"
                subtitle="Por exemplo: diligências em prefeituras, cartórios, juntas comerciais e outros locais."
                text="Serviço de correspondentes jurídicos em várias cidades."
                icon={<PiSuitcase />}
              />

              <Document
                url="/kits"
                title="Solicitar um kit"
                subtitle="Ideal para Due Diligence. Escolha os documentos, configure os campos fixos e crie seu kit."
                text="Solicite ou crie um kit predefinido de documentos."
                icon={<IoDocumentsOutline />}
              />

            </styles.RequestGroup>

          </styles.RequestSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
