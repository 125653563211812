import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import * as styles from './style';
import img from '../../assets/jk-logo.png';

export default function Loading() {
  return (
    <styles.LoadingSt>
      <img src={img} alt="logo" />
      <AiOutlineLoading3Quarters />
    </styles.LoadingSt>
  );
}
