import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoWarning } from 'react-icons/io5';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import * as styles from './style';
import banner from '../../assets/sign-banner.png';
import { auth, db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [warn, setWarn] = useState(false);
  const [warnText, setWarnText] = useState('');
  const [load, setLoad] = useState(false);

  const { storageUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoad(true);

      if (email === '') {
        setWarn(true);
        throw new Error('O email está vazio!');
      }

      if (password === '') {
        setWarn(true);
        throw new Error('A senha está vazia!');
      }

      await signInWithEmailAndPassword(auth, email, password)
        .then(async (value) => {
          const { uid } = value.user;

          const docRef = doc(db, 'users', uid);
          const userProfile = await getDoc(docRef);

          const data = {
            uid,
            email: value.user.email,
            name: userProfile.data().name,
            img: userProfile.data().img,
          };

          storageUser(data);
        })
        .catch((erro) => {
          throw Error(erro.message);
        });
    } catch (error) {
      const result = error.message.split(/\(([^)]+)\)/, 2);
      const resultMsg = result[1];

      setWarnText(error.message);
      switch (resultMsg) {
        case 'auth/invalid-email':
          setWarn(true);
          setWarnText('Digite um email válido');
          break;

        case 'auth/user-not-found':
          setWarn(true);
          setWarnText('A conta do usuário não existe');
          break;

        case 'auth/wrong-password':
          setWarn(true);
          setWarnText('A senha está incorreta');
          break;

        default:
          break;
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <styles.Sing>
      <styles.SingBanner $isBanner={banner} />

      <styles.SingInfo>
        <h2>Bem vindo a plataforma JK</h2>
        <span>Certidões, Imóveis e Construções</span>

        <h3>Acesse sua conta</h3>

        <styles.SingForm>

          {warn && (
            <styles.SingWarn>
              <IoWarning />
              <h3>{warnText}</h3>
            </styles.SingWarn>
          )}

          <styles.SingGroupInput>
            <span>Email</span>
            <input type="email" name="email" placeholder="Digite seu email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </styles.SingGroupInput>

          <styles.SingGroupInput>
            <span>Senha</span>
            <input type="password" placeholder="Digite sua senha" value={password} onChange={(e) => setPassword(e.target.value)} />
          </styles.SingGroupInput>

          <styles.SingGroupBox>
            <div>
              <input type="checkbox" />
              <span>Manter-me conectado(a)</span>
            </div>

            <p>Esqueceu a senha?</p>
          </styles.SingGroupBox>

          <button type="submit" onClick={handleSubmit}>{load ? 'Acessando...' : 'Acessar'}</button>
        </styles.SingForm>

        <p>Não tem uma conta?</p>

        <Link to="/register">Crie uma conta</Link>

      </styles.SingInfo>

    </styles.Sing>
  );
}
