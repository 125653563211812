import styled from 'styled-components';

export const MyRequestOperations = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .selected{
    background-color: ${(props) => props.theme.textDark} !important;
    color: ${(props) => props.theme.textLigth} !important;

    p{
      color: ${(props) => props.theme.textLigth} !important;
    }
  }
`;

export const MyRequestPagament = styled.div`
  width: 100%;
  display: flex;

  button{
    font-size: 16px;
    padding: 5px 10px;
    background-color: transparent;
    font-weight: 300;
    border-radius: 0.3rem;
    color: ${(props) => props.theme.layout};
    border: 1px solid ${(props) => props.theme.layout};
    cursor: pointer;
    margin-right: 15px;

    &:nth-child(${(props) => props.$isNumber}){
      background-color: ${(props) => props.theme.layout};
      color: ${(props) => props.theme.textLigth};
    }

    &:hover{
      transition: 0.7s;
      background-color: ${(props) => props.theme.layout};
      color: ${(props) => props.theme.textLigth};
    }
  }
`;

export const MyRequestOperation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  /* background-color: ${(props) => props.theme.backgroundHover}; */
  padding: 12px 20px;
  border-radius: 0.5rem;
  cursor: pointer;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);



  p{
    font-size: 18px;
    font-weight: 400;
    color: #F5190A;
    color: ${(props) => (props.$isYellow ? '#D7A800' : '')};
    color: ${(props) => (props.$isGreen ? '#00BD2C' : '')};
  }

  h2{
    font-size: 22px;
    font-weight: 600;
  }
`;

export const MyRequestFilter = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  border-radius: 0.3rem;
  border: 1px solid ${(props) => props.theme.textDark};
  padding: 10px 20px;
  margin-top: 15px;

  button{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: ${(props) => props.theme.textDark};
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    svg{
      font-size: 20px;
      margin-left: 10px;
    }
  }
`;

export const MyRequestFilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const MyRequestFilterInput = styled.div`
  display: flex;
  flex-direction: column;

  span{
    font-size: 15px;
  }

  input{
    margin-top: 5px;
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 0.3rem;
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 14px;
  }
`;

export const MyRequestTables = styled.table`
  width: 100%;
  margin-top: 15px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    text-align: center;
    font-weight: 300;

    &:nth-child(3){
      font-weight: 600;
    }

    &:nth-child(5){
      svg{
        cursor: pointer;
        color: ${(props) => props.theme.layout};
      }
    }
  }

  span{
    padding: 5px 8px;
    border-radius: 0.5rem;
    color: ${(props) => props.theme.textLigth};
  }

  a{
    position: relative;
    top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .table_access_false{
    background-color: #F5190A;
  }

  .table_access_true{
    background-color: #01F510;
  }
  
  .table_access_progress{
    background-color: #D7A800;
  }

  .icon_access_false{
    color: #F5190A !important;
  }

  .icon_access_true{
    color: #01F510 !important;
  }
  
  .icon_access_progress{
    color: #D7A800 !important;
  }
`;
