import React, { useEffect, useState } from 'react';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BiSolidLike } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import {
  MyRequestFilter, MyRequestFilterGroup, MyRequestFilterInput, MyRequestTables,
} from '../MyRequest/style';
import useApplication from '../../store/Application';
import useUser from '../../store/User';
import { PendingClear } from '../Pending/style';

export default function Financial() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [show, setShow] = useState(false);
  const { user } = useUser();
  const { fetchApplication, application } = useApplication();

  useEffect(() => {
    fetchApplication(user.uid);
  }, [user]);

  const handleFilter = (apl) => {
    if (!startDate && !endDate) {
      return true;
    }

    const orderDate = new Date(apl.dateFormat);
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (startDate && !endDate) {
      return orderDate >= start;
    }

    if (!startDate && endDate) {
      return orderDate <= end;
    }

    return orderDate >= start && orderDate <= end;
  };

  const filteredApplications = application && application.filter(handleFilter);

  const totalValue = application && filteredApplications.reduce((total, apl) => total + parseFloat(apl.prince), 0).toFixed(2).replace('.', ',');

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Financeiro" url="financial" icon={<MdOutlineAttachMoney />} />

          <styles.FinancialSt>
            <h3>Dados do seu faturamento</h3>

            {filteredApplications && (
              filteredApplications.length >= 0 && (
                <MyRequestFilter>
                  <button type="button" onClick={() => setShow(!show)}>
                    Filtro

                    {show ? <IoIosArrowDown /> : <IoIosArrowUp /> }

                  </button>

                  {show && (
                  <MyRequestFilterGroup>
                    <MyRequestFilterInput>
                      <span>Inicio do periodo</span>
                      <input
                        type="text"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Data de início"
                      />
                    </MyRequestFilterInput>

                    <MyRequestFilterInput>
                      <span>Final</span>
                      <input
                        type="text"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="Data de término"
                      />
                    </MyRequestFilterInput>

                  </MyRequestFilterGroup>
                  )}

                </MyRequestFilter>
              )
            )}

            {filteredApplications
            && filteredApplications.length > 0 ? (

              <MyRequestTables>
                <thead>
                  <tr>
                    <th>N.º do pedido</th>
                    <th>Nome do pedido</th>
                    <th>Data</th>
                    <th>Valor</th>
                    <th>Aprovado</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredApplications && filteredApplications.map((apl) => (
                    <tr key={apl.id}>
                      <td>{apl.numberId}</td>
                      <td>{apl.nameOrder}</td>
                      <td>{apl.dateFormat}</td>
                      <td>{parseFloat(apl.prince).toFixed(2).replace('.', ',')}</td>
                      <td>
                        {apl.pagament === 'approved' ? (<BiSolidLike size={26} color="red" />) : (<HiOutlineDocumentReport size={26} />)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </MyRequestTables>

              ) : (
                <PendingClear>
                  <MdOutlineAttachMoney />
                  <h6>Nenhum pedido encontrado</h6>

                  <span>Realize um pedido, entre e selecione uma das opções e efetue seu pedido</span>

                  <Link to="/request">Realize um pedido agora mesmo</Link>
                </PendingClear>
              )}

            {filteredApplications
              && filteredApplications.length > 0 && (
                <styles.FinancialValue>
                  <h4>
                    Total Faturado:
                    <b>
                      R$
                      {totalValue}
                    </b>
                  </h4>

                  <button type="button">Imprimir</button>
                </styles.FinancialValue>
            )}
          </styles.FinancialSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
