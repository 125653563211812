import styled from 'styled-components';

export const RequestSt = styled.section`
  display: flex;
  flex-direction: column;

  h3{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.textDark};
    margin-bottom: 10px;
  }
`;

export const RequestGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
`;
