import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { IMaskInput } from 'react-imask';
import * as styles from './style';
import EditOrderSelect from '../EditOrderSelect';
import useOrder from '../../store/Order';
import { FormVerif } from '../../pages/Form/style';
import { PopUpWarn } from '../../Popups';

export default function EditAppl({ func, obj }) {
  const [newDocs, setNewDocs] = useState('');
  const [newName, setNewName] = useState('');
  const [newNameOrder, setNewNameOrder] = useState('');
  const [newOrderSelect, setNewOrderSelect] = useState([]);
  const [newState, setNewState] = useState('');
  const [show, setShow] = useState(false);
  const [verif, setVerif] = useState(false);

  const {
    setOrderCart, orderCart,
  } = useOrder();

  const {
    docs, name, nameOrder, orderSelect, state, id,
  } = obj;

  useEffect(() => {
    function isCPFOrCNPJ(value) {
      // Regex para CNPJ: 00.000.000/0000-00
      const cnpjPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
      // Regex para CPF: 000.000.000-00
      const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

      if (cnpjPattern.test(value)) {
        setVerif(false);
      } if (cpfPattern.test(value)) {
        setVerif(true);
      }
    }

    isCPFOrCNPJ(docs);

    setNewDocs(docs);
    setNewName(name);
    setNewNameOrder(nameOrder);
    setNewOrderSelect(orderSelect);
    setNewState(state);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = today.getFullYear();

    const formatDate = `${day}/${month}/${year}`;

    if (newNameOrder !== '' && newName !== '' && newDocs !== '' && newState !== '') {
      const data = {
        ...obj,
        docs: newDocs,
        name: newName,
        nameOrder: newNameOrder,
        orderSelect: newOrderSelect,
        state: newState,
        date: today,
        dateFormat: formatDate,
      };

      const listCart = orderCart.filter((oc) => oc.id !== id) || [];

      listCart.push(data);

      setOrderCart(listCart);

      func();
    } else {
      PopUpWarn('Um dos campos estão vazios!');
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Editar pedido</h2>

        <FormVerif $isVerif={verif}>
          <button type="button" onClick={() => setVerif(true)}>Cpf</button>
          <button type="button" onClick={() => setVerif(false)}>Cnpj</button>
        </FormVerif>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>
            <span>Nome do pedido</span>
            <input type="text" placeholder="Digite o nome do pedido" value={newNameOrder} onChange={(e) => setNewNameOrder(e.target.value)} />
          </styles.OrderFormInput>
        </styles.OrderFormGroup>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>

            {verif && (<span>Nome Completo</span>)}
            {!verif && (<span>Nome da empresa</span>)}
            <input type="text" placeholder="Digite o nome para a solicitação" value={newName} onChange={(e) => setNewName(e.target.value)} />
          </styles.OrderFormInput>

          <styles.OrderFormSelect>
            <span>Selecione a coleção</span>

            <select value={newState} onChange={(e) => setNewState(e.target.value)}>
              <option selected />
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>

          </styles.OrderFormSelect>
        </styles.OrderFormGroup>

        <styles.OrderFormGroup>
          {verif && (
            <styles.OrderFormInput>
              <span>CPF</span>
              <IMaskInput mask="000.000.000-00" type="text" name="cpf" placeholder="Digite seu CPF" value={newDocs} onChange={(e) => setNewDocs(e.target.value)} />
            </styles.OrderFormInput>
          )}

          {!verif && (
            <styles.OrderFormInput>
              <span>CNPJ</span>
              <IMaskInput mask="00.000.000/0000-00" type="text" name="cnpj" placeholder="Digite seu CNPJ" value={newDocs} onChange={(e) => setNewDocs(e.target.value)} />
            </styles.OrderFormInput>
          )}

          <styles.OrderFormUl>
            <span>Documentos solicitandos</span>
            <ul>
              {newOrderSelect.map((os) => (
                <li>{os.name}</li>
              ))}

            </ul>
            <button type="button" onClick={() => setShow(true)}>Alterar solicitações</button>
          </styles.OrderFormUl>
        </styles.OrderFormGroup>

        <button type="button" className="button_submit" onClick={handleSubmit}>Editar</button>
      </styles.FormOrderForm>

      {show && (
        <EditOrderSelect func={() => setShow(false)} obj={newOrderSelect} setObj={setNewOrderSelect} />
      )}
    </styles.FormOrderSt>
  );
}
