import styled from 'styled-components';

export const DocumentSt = styled.div`
  width: 100%;
  height: auto;
  margin-right: 15px;

  a{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${(props) => props.theme.backgroundHover};
    padding: 15px 15px 30px 15px;
    color: ${(props) => props.theme.textDark};
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

    svg{
      font-size: 50px;
      margin-bottom: 15px;
    }

    p{
      font-size: 16px;
      font-weight: 500;
    }
    
    span{
      font-size: 14px;
      font-weight: 300;
      padding-bottom: 10px;
      margin-bottom: 15px;
      margin-top: 10px;
      border-bottom: 1px solid ${(props) => props.theme.textDark};
    }

    &:hover{
      transition: 0.7s;
      transform: scale(1.01);

      -webkit-box-shadow: 0px 0px 10px 0px rgba(210, 22, 21, 0.5);
      -moz-box-shadow: 0px 0px 10px 0px rgba(210, 22, 21, 0.5);
      box-shadow: 0px 0px 10px 0px rgba(210, 22, 21, 0.5);
    }
  }
`;
