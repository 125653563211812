import React, { useEffect, useState } from 'react';
import { FaPencil, FaPlus } from 'react-icons/fa6';
import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import useOrder from '../../store/Order';
import { PopUpWarn } from '../../Popups';
import { DocumentTitle } from '../DocumentsDefault/style';

export default function FormDefault() {
  const initialOrders = {
    docs: '',
    name: '',
    verif: false,
    show: true,
    state: '',
    city: '',
    citys: [],
  };

  const [orders, setOrders] = useState([initialOrders]);
  const [estados, setEstados] = useState([]);
  const [nameOrder, setNameOrder] = useState('');

  const {
    setOrderDefault,
  } = useOrder();
  const navigate = useNavigate();

  // Api para Estados e Cidades dinâmico

  useEffect(() => {
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((response) => response.json())
      .then((data) => {
        setEstados(data);
      })
      .catch((error) => console.error('Error fetching estados:', error));
  }, []);

  const addOrder = () => {
    setOrders([...orders, { ...initialOrders }]);
  };

  const isOrderEmpty = (order) => {
    const fieldsToCheck = { ...order };
    delete fieldsToCheck.show;
    delete fieldsToCheck.verif;
    delete fieldsToCheck.citys;

    return Object.values(fieldsToCheck).some((value) => value === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (nameOrder === '') {
      PopUpWarn('Coloque um nome no pedido');
      return;
    }

    if (orders.some((order) => order.city === '' || order.state === '')) {
      PopUpWarn('Cidade e estado não podem estar vazios!');
      return;
    }

    if (orders.some((order) => isOrderEmpty(order))) {
      PopUpWarn('Um dos campos dos pedidos está vazio!');
      return;
    }

    // Processar os pedidos se todas as verificações passarem
    const listCart = [nameOrder];

    orders.forEach((order) => {
      const data = {
        id: listCart.length + 1,
        ...order,
        orderSelect: [],
      };

      delete data.show;
      delete data.verif;
      delete data.citys;

      listCart.push(data);
    });

    setOrderDefault(listCart);

    navigate('/requestdocument');
  };
  const updateVerif = (index, newVerifValue) => {
    const updatedOrders = orders.map((order, i) => {
      if (i === index) {
        return { ...order, verif: newVerifValue };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const updateShow = (index, newVerifValue) => {
    const updatedOrders = orders.map((order, i) => {
      if (i === index) {
        return { ...order, show: newVerifValue };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const deleteOrder = (index) => {
    const updatedOrders = orders.filter((_, i) => i !== index);
    setOrders(updatedOrders);
  };

  const updateOrderField = (index, field, value) => {
    const updatedOrders = orders.map((order, i) => {
      if (i === index) {
        return { ...order, [field]: value };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  const handleState = async (index, value) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);
      const data = await response.json();
      const updatedOrders = orders.map((order, i) => {
        if (i === index) {
          return {
            ...order, state: value, city: '', citys: data,
          };
        }
        return order;
      });
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error fetching cidades:', error);
      const updatedOrders = orders.map((order, i) => {
        if (i === index) {
          return {
            ...order, state: value, city: '', citys: [],
          };
        }
        return order;
      });
      setOrders(updatedOrders);
    }
  };

  const updateState = (index, value) => {
    handleState(index, value);
  };

  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>

          <Title text="Preencha os dados do pedido" url="form" icon={<FaPencil />} />
          <styles.FormSt>

            <DocumentTitle>
              <h3>Selecione um nome para seu pedido</h3>

              <input type="text" placeholder="Digite um nome para o pedido" value={nameOrder} onChange={(e) => setNameOrder(e.target.value)} />
            </DocumentTitle>

            <styles.FormFlex>

              <styles.FormGroup>

                <h4>Pessoas para a certidão</h4>

                {orders.map((order, index) => (
                  <styles.FormOrders>

                    <styles.FormOrdersTop>
                      <span>{order.nameOrder || 'Nome do pedido'}</span>

                      <div>
                        {index === 1 && <button className="btn_delete" type="button" onClick={() => deleteOrder(index)}><MdDelete /></button>}

                        {order.show ? (
                          <button type="button" onClick={() => updateShow(index, !order.show)}>
                            <IoMdArrowDropdown />
                          </button>
                        ) : (
                          <button type="button" onClick={() => updateShow(index, !order.show)}>
                            <IoMdArrowDropup />
                          </button>
                        )}
                      </div>

                    </styles.FormOrdersTop>

                    {order.show && (
                      <>
                        <styles.FormVerif $isVerif={order.verif}>
                          <button type="button" onClick={() => updateVerif(index, true)}>Cpf</button>
                          <button type="button" onClick={() => updateVerif(index, false)}>Cnpj</button>
                        </styles.FormVerif>

                        <styles.FormState>
                          <div>
                            <span>Estado do documento</span>
                            <select value={order.state} onChange={(e) => updateState(index, e.target.value)}>
                              <option selected />
                              {estados.map((estado) => (
                                <option key={estado.id} value={estado.sigla}>
                                  {estado.nome}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <span>Cidade do documento</span>
                            <select value={order.city} onChange={(e) => updateOrderField(index, 'city', e.target.value)}>
                              <option value="" />
                              {Array.isArray(order.citys) && order.citys.map((cidade) => (
                                <option key={cidade.id} value={cidade.nome}>
                                  {cidade.nome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </styles.FormState>

                        {order.verif ? (
                          <styles.FormInput>
                            <span>CPF</span>
                            <IMaskInput
                              mask="000.000.000-00"
                              type="text"
                              name="cpf"
                              placeholder="Digite seu CPF"
                              value={order.docs}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].docs = e.target.value;
                                setOrders(newOrders);
                              }}
                            />

                            <span>Nome completo</span>
                            <input
                              type="text"
                              name="name"
                              placeholder="Digite o nome completo"
                              value={order.name}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].name = e.target.value;
                                setOrders(newOrders);
                              }}
                            />
                          </styles.FormInput>
                        ) : (
                          <styles.FormInput>
                            <span>CNPJ</span>
                            <IMaskInput
                              mask="00.000.000/0000-00"
                              type="text"
                              name="cnpj"
                              placeholder="Digite seu CNPJ"
                              value={order.docs}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].docs = e.target.value;
                                setOrders(newOrders);
                              }}
                            />

                            <span>Nome da empresa</span>
                            <input
                              type="text"
                              name="name"
                              placeholder="Digite o nome da empresa"
                              value={order.name}
                              onChange={(e) => {
                                const newOrders = [...orders];
                                newOrders[index].name = e.target.value;
                                setOrders(newOrders);
                              }}
                            />
                          </styles.FormInput>
                        )}
                      </>

                    )}
                  </styles.FormOrders>
                ))}

                <styles.FormAdd type="button" onClick={addOrder} className="addButton">
                  <FaPlus />
                  Adicionar Mais
                </styles.FormAdd>

                <button type="submit" className="send_form" onClick={handleSubmit}>Enviar</button>

              </styles.FormGroup>

              <styles.FormInfos>
                <h4>Dados do pedido</h4>

                {orders.map((order) => (
                  <styles.FormInfosPeople>

                    <h5>{order.nameOrder}</h5>

                    <div>
                      <p>
                        Documento:
                        <b>
                          {order.docs}
                        </b>
                      </p>
                      <p>
                        Nome:
                        <b>
                          {order.name}
                        </b>
                      </p>
                    </div>

                    <div>
                      <p>
                        Estado:
                        <b>
                          {order.state}
                        </b>
                      </p>
                      <p>
                        Cidade:
                        <b>
                          {order.city}
                        </b>
                      </p>
                    </div>
                  </styles.FormInfosPeople>
                ))}
              </styles.FormInfos>

            </styles.FormFlex>

          </styles.FormSt>
        </DashboardSt>
      </Center>
    </article>
  );
}
