import styled from 'styled-components';

export const MyRequestId = styled.section`
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 15px 20px;
  border-radius: 0.5rem;

  a{
    font-size: 24px;
  }

  h2{
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
  }
`;

export const MyRequestIdInfo = styled.div`
  margin-top: 15px;

  h4{
    font-size: 18px;
    font-weight: 500;
  }

  ul{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    list-style-type: circle;
    list-style-position: inside;

    li{
      font-size: 16px;
      margin: 5px 0;
    }
  }

`;
