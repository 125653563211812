import React from 'react';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import * as styles from './style';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';

export default function Help() {
  return (
    <article className="article_flex">
      <Header />

      <Center>
        <DashboardSt>
          <Title text="Ajuda" url="requestdocument" icon={<IoMdHelpCircleOutline />} />

          <styles.HelpInfos>
            <h3>Informações sobre o sistema</h3>
            <p>Nosso sistema foi desenvolvido para facilitar </p>
          </styles.HelpInfos>
        </DashboardSt>
      </Center>
    </article>
  );
}
